import { escapeRegExp } from 'lodash';

export enum FilterType {
  DropDown,
  Text,
  Boolean,
  DateRange,
}

export type TextFieldFilter = {
  type: FilterType.Text;
  textFilter: string;
};

export type DropdownFieldFilter = {
  type: FilterType.DropDown;
  dropdownFilter: string[];
};

export interface BooleanFieldFilter {
  type: FilterType.Boolean;
  booleanFilter?: boolean;
}

export interface DateFieldFilter {
  type: FilterType.DateRange;
  startDate?: Date;
  endDate?: Date;
}

export type Filters = TextFieldFilter | DropdownFieldFilter | BooleanFieldFilter | DateFieldFilter;

export type FilterRules<T> = {
  [property in keyof T]?: Filters;
};

/**
 * Given an array of items of type T and filter rules of type FilterRules<T>,
 * apply the rules as defined and return the filtered array of elements
 *
 * @param items Items to be filtered
 * @param rules Filter rules
 */
export function processRules<T>(items: T[], rules: FilterRules<T>): T[] {
  let newItems: T[] = [...items];

  for (const property in rules) {
    const rule = rules[property];

    if (rule === undefined) {
      continue;
    }

    switch (rule.type) {
      case FilterType.Boolean: {
        if (rule.booleanFilter !== undefined) {
          newItems = newItems.filter((item) => {
            const itemValue = item[property];

            if (typeof itemValue === 'boolean') {
              return itemValue === rule.booleanFilter;
            } else if (Number(itemValue) === 0) {
              return false === rule.booleanFilter;
            } else if (Number(itemValue) === 1) {
              return true === rule.booleanFilter;
            } else {
              return true;
            }
          });
        }

        break;
      }

      case FilterType.Text: {
        const textRegex = new RegExp(rule.textFilter, 'i');
        newItems = newItems.filter((item) => {
          const itemValue = `${item[property]}`;

          return itemValue.search(textRegex) !== -1;
        });

        break;
      }

      case FilterType.DropDown: {
        const dropdownRegex = new RegExp(
          // use ^ and $ to assert the DropdownSelection is exact. Run `escapeRegex` on the value
          // for cases such as one of the dropdown options having a backslash in it (e.g. northamerica\danigon)
          // which would break the regex expression.
          rule.dropdownFilter
            .map((value) => {
              return `^${escapeRegExp(value)}$`;
            })
            .join('|'),
          'i'
        );

        newItems = newItems.filter((item) => {
          const itemValue = `${item[property]}`;

          const isAvailable = dropdownRegex.test(itemValue);

          return isAvailable;
        });
        break;
      }

      case FilterType.DateRange:
        newItems = newItems.filter((item) => {
          const itemValue = item[property];
          let targetDate = undefined;

          if (typeof itemValue === 'string') {
            const validDate = Date.parse(itemValue);

            if (!isNaN(validDate)) {
              // The DateFilter was applied to a string field that can't be
              // converted to a datetime object
              targetDate = new Date(itemValue);

              // If we reach this point, then the user has specified a daterange
              // filter on a string field that can't be converted to DateTime
            } else {
              return true;
            }
          } else if (itemValue instanceof Date) {
            targetDate = itemValue;
          }

          if (targetDate) {
            if (rule.startDate && rule.endDate) {
              // a before date and after date was defined
              return rule.startDate <= targetDate && targetDate <= rule.endDate;
            } else if (rule.startDate && !rule.endDate) {
              // only a before date was defined
              return rule.startDate <= targetDate;
            } else if (!rule.startDate && rule.endDate) {
              // only an after date was defined
              return targetDate <= rule.endDate;
            } else {
              // neither a before or after date defined, not filterin
              return true;
            }
          } else {
            // itemValue wasn't a datestring or date, unable to be filtered
            return true;
          }
        });
    }
  }

  return newItems;
}

/**
 * Given a FilterRules<T> object, go through and determine how many filters
 * are currently applied (applied meaning the rule is not the default version
 * of the rule). For instance, if neither startDate or endDate is defined for a
 * DateRange rule, then that filter is not currently applied.
 *
 * @param rules Object of rules
 */
export function countAppliedFilters<T>(rules: FilterRules<T>): number {
  let count = 0;

  for (const property in rules) {
    const rule = rules[property];

    if (rule === undefined) {
      continue;
    }

    switch (rule.type) {
      case FilterType.Boolean:
        if (rule.booleanFilter !== undefined) {
          count += 1;
        }

        break;

      case FilterType.Text:
        if (rule.textFilter !== '') {
          count += 1;
        }

        break;

      case FilterType.DropDown:
        if (rule.dropdownFilter.length > 0) {
          count += 1;
        }

        break;

      case FilterType.DateRange:
        if (rule.startDate !== undefined || rule.endDate !== undefined) {
          count += 1;
        }
        break;
    }
  }

  return count;
}

export function convertToSearchParams<T>(rules: FilterRules<T>): URLSearchParams {
  const searchParams = new URLSearchParams();

  for (const property in rules) {
    const rule = rules[property];

    if (rule === undefined) {
      continue;
    }

    switch (rule.type) {
      case FilterType.DropDown:
        if (rule.dropdownFilter.length > 0) {
          const dropdownAsParam = rule.dropdownFilter.join(',');

          searchParams.set(property, dropdownAsParam);
        }

        break;
      case FilterType.Text:
        if (rule.textFilter.length > 0) {
          searchParams.set(property, rule.textFilter);
        }

        break;
      case FilterType.DateRange: {
        // to handle the DateRange filter, we assume a 2 length array
        // where the first index is startDate and second is endDate.
        const startDateAsParam = rule.startDate?.toISOString();
        const endDateAsParam = rule.endDate?.toISOString();
        const dateParamArray = [startDateAsParam, endDateAsParam];

        if (startDateAsParam || endDateAsParam) {
          searchParams.set(property, dateParamArray.toString());
        }

        break;
      }
      case FilterType.Boolean:
        if (rule.booleanFilter !== undefined) {
          searchParams.set(property, rule.booleanFilter.toString());
        }

        break;
      default:
        break;
    }
  }

  return searchParams;
}
