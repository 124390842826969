import { Label, SearchBox } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React from 'react';

import { IExtendedColumn } from '../ExtendedDetailsList';
import { FilterRules, FilterType, TextFieldFilter } from '../filterUtils';

export type ITextFilterProps<T> = {
  column: IExtendedColumn;
  filterRules: FilterRules<T>;
  setFilterRules: React.Dispatch<React.SetStateAction<FilterRules<T>>>;
};

export function TextFilter<T>(props: ITextFilterProps<T>): JSX.Element {
  const { column, filterRules, setFilterRules } = props;
  const key = column.fieldName as keyof T;

  const textRule = filterRules[key] as TextFieldFilter;

  const onTextChange = (newValue?: string): void => {
    const newFilterRules = { ...filterRules };
    const textFilter = newValue ?? '';

    newFilterRules[key] = {
      type: FilterType.Text,
      textFilter: textFilter,
    };

    setFilterRules(newFilterRules);
  };

  const onClear = () => {
    onTextChange('');
  };

  const id = useId(column.key);

  if (textRule?.textFilter === undefined) return <React.Fragment />;

  return (
    <>
      <Label htmlFor={id}>{column.name}</Label>
      <SearchBox
        id={id}
        defaultValue={textRule.textFilter}
        placeholder={`Filter by ${column.name}`}
        onSearch={onTextChange}
        onClear={onClear}
        styles={{ root: { marginTop: '0 !important' } }}
      />
    </>
  );
}
