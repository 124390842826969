import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';
import TestResourcePage from '../components/TestResource';
import LeasedTenantPage from '../components/UserManagement/LeasedTenant';
import AdminActions from '../components/AdminActions';

const Home = lazy(() => import('../components/Home'));
const Pools = lazy(() => import('../components/Pools'));
const PoolHomePage = lazy(() => import('../components/Pool'));
const Requests = lazy(() => import('../components/Requests'));
const UserManagement = lazy(() => import('../components/UserManagement'));

function Router(): JSX.Element {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route exact path={'/Pools'} component={Pools} />
        <Route exact path={'/Requests'} component={Requests} />
        <Route exact path={'/UserManagement'} component={UserManagement} />
        <Route exact path={'/AdminActions'} component={AdminActions} />
        <Route exact path={'/Pools/:poolName/testAssets'} component={PoolHomePage} />
        <Route exact path={'/Pools/:poolName/testAssets/:testAssetId'} component={TestResourcePage} />
        <Route exact path={'/Tenants/:tenantId'} component={LeasedTenantPage} />
      </Switch>
    </Suspense>
  );
}

export default Router;
