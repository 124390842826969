import React from 'react';
import { Label, Stack, Icon, Separator, TooltipHost, DirectionalHint } from '@fluentui/react';
import { separatorMarginStyle, tooltipHostStyles } from '../../constants/styles';

type InputTitleProps = {
  title: string;
  tooltipContent?: string;
};

const InputTitle: React.FC<InputTitleProps> = ({ title, tooltipContent }) => {
  return (
    <>
      <Label style={{ marginTop: '10px' }}>
        <Stack horizontal verticalAlign="center">
          <span>{title}</span>
          {tooltipContent && (
            <TooltipHost
              content={tooltipContent}
              directionalHint={DirectionalHint.rightCenter}
              styles={tooltipHostStyles}
            >
              <Icon iconName="Info" />
            </TooltipHost>
          )}
        </Stack>
      </Label>
      <Separator styles={separatorMarginStyle('-15px', '-5px')} />
    </>
  );
};

export default InputTitle;
