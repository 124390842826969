import { Label, Stack, Text } from '@fluentui/react';
import React from 'react';

import { IExtendedColumn } from '../ExtendedDetailsList';
import { DateFieldFilter, FilterRules } from '../filterUtils';
import { FilterDatePicker } from './FilterDatePicker';

export type IDateRangeFilterProps<T> = {
  column: IExtendedColumn;
  filterRules: FilterRules<T>;
  setFilterRules: React.Dispatch<React.SetStateAction<FilterRules<T>>>;
};

export function DateRangeFilter<T>(props: IDateRangeFilterProps<T>): JSX.Element {
  const { column, filterRules, setFilterRules } = props;
  const key = column.fieldName as keyof T;

  const dateFieldFilter = filterRules[key] as DateFieldFilter;

  const onStartDateChange = (date: Date | null | undefined): void => {
    const newFilterRules = { ...filterRules };

    const currentDateFilterRule = newFilterRules[key] as DateFieldFilter;

    if (date === null) {
      date = undefined;
    }

    if (date === undefined && currentDateFilterRule.startDate === undefined) {
      return;
    } else {
      currentDateFilterRule.startDate = date;

      setFilterRules(newFilterRules);
    }
  };

  const onEndDateChange = (date: Date | null | undefined): void => {
    const newFilterRules = { ...filterRules };

    const currentDateFilterRule = newFilterRules[key] as DateFieldFilter;

    if (date === null) {
      date = undefined;
    }

    if (date === undefined && currentDateFilterRule.endDate === undefined) {
      return;
    } else {
      currentDateFilterRule.endDate = date;

      setFilterRules(newFilterRules);
    }
  };

  const generateDateRangeHelperText = () => {
    const { startDate, endDate } = dateFieldFilter;

    if (startDate && endDate) {
      return (
        <Text>
          Showing results with {column.name} between {startDate.toLocaleDateString()} and {endDate.toLocaleDateString()}
          .
        </Text>
      );
    } else if (startDate) {
      return (
        <Text>
          Showing results with {column.name} after {startDate.toLocaleDateString()}.
        </Text>
      );
    } else if (endDate) {
      return (
        <Text>
          Showing results with {column.name} before {endDate.toLocaleDateString()}.
        </Text>
      );
    } else {
      return <Text>Showing all dates for {column.name}.</Text>;
    }
  };

  const dateFilterId = `input:for:${column.key}`;

  return (
    <>
      <Label htmlFor={dateFilterId}>{column.name}</Label>
      <Stack id={dateFilterId}>
        <FilterDatePicker onSelectDate={onStartDateChange} label="Start Date" value={dateFieldFilter.startDate} />
        <FilterDatePicker onSelectDate={onEndDateChange} label="End Date" value={dateFieldFilter.endDate} />
        {generateDateRangeHelperText()}
      </Stack>
    </>
  );
}
