import React from 'react';
import { Toggle, TooltipHost, Icon, DirectionalHint, Stack, Label } from '@fluentui/react';
import { tooltipHostStyles } from '../../constants/styles';
import { toKebabCase } from '../../functions/helpers';
import { AssetPoolTooltipContent, AssetPoolTooltipContentKeys } from '../../constants/constants';

type ToggleWithTooltipProps = {
  label: AssetPoolTooltipContentKeys;
  checked: boolean;
  onChange: (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => void;
  disabled: boolean;
};

const ToggleWithTooltip: React.FC<ToggleWithTooltipProps> = ({ label, checked, onChange, disabled }) => {
  const id = toKebabCase(label);
  const tooltipContent = AssetPoolTooltipContent[label];

  return (
    <Stack horizontal verticalAlign="center">
      <Label htmlFor={id} style={{ marginRight: '2px' }}>
        {label}
      </Label>
      <TooltipHost content={tooltipContent} directionalHint={DirectionalHint.rightCenter} styles={tooltipHostStyles}>
        <Icon iconName="Info" />
      </TooltipHost>
      <Toggle
        id={id}
        inlineLabel
        onText="True"
        offText="False"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        styles={{ root: { marginLeft: '8px', marginTop: '6px' } }}
      />
    </Stack>
  );
};

export default ToggleWithTooltip;
