import { INavLink, INavLinkGroup, INavStyles, Nav } from '@fluentui/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserRoleContext } from '../../auth/UserRoleContext';

const navigationStyles: Partial<INavStyles> = {
  root: {
    height: '100vh',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
  },
};

const getNavLinks = (isAdmin: boolean): INavLinkGroup[] => [
  {
    links: [
      {
        name: 'Home',
        url: `${process.env.PUBLIC_URL}`,
        key: `${process.env.PUBLIC_URL}`,
        iconProps: {
          iconName: 'Home',
          styles: {
            root: {
              fontSize: 24,
              color: '#106ebe',
            },
          },
        },
      },
      {
        name: 'Resource Pools',
        url: `${process.env.PUBLIC_URL}/Pools`,
        key: `${process.env.PUBLIC_URL}/Pools`,
        iconProps: {
          iconName: 'ProductVariant',
          styles: {
            root: {
              fontSize: 24,
              color: '#106ebe',
            },
          },
        },
      },
      {
        name: 'Requests',
        url: `${process.env.PUBLIC_URL}/Requests`,
        key: `${process.env.PUBLIC_URL}/Requests`,
        iconProps: {
          iconName: 'PageAdd',
          styles: {
            root: {
              fontSize: 24,
              color: '#106ebe',
            },
          },
        },
      },
      {
        name: 'User Management',
        url: `${process.env.PUBLIC_URL}/UserManagement`,
        key: `${process.env.PUBLIC_URL}/UserManagement`,
        iconProps: {
          iconName: 'TaskManager',
          styles: {
            root: {
              fontSize: 24,
              color: '#106ebe',
            },
          },
        },
        disabled: !isAdmin,
      },
      {
        name: 'Admin Actions',
        url: `${process.env.PUBLIC_URL}/AdminActions`,
        key: `${process.env.PUBLIC_URL}/AdminActions`,
        iconProps: {
          iconName: 'PlayerSettings',
          styles: {
            root: {
              fontSize: 24,
              color: '#106ebe',
            },
          },
        },
        disabled: !isAdmin,
      },
    ],
  },
];

function SidebarMenu(): JSX.Element {
  const history = useHistory();

  const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    ev?.preventDefault();

    if (item) {
      history.push(item.url);
    }
  };

  const { isAdmin } = useContext(UserRoleContext);
  const navLinks = getNavLinks(isAdmin);

  return (
    <div className="SidebarMenu">
      <Nav onLinkClick={onLinkClick} groups={navLinks} styles={navigationStyles} />
    </div>
  );
}

export default SidebarMenu;
