import { getTheme, IStackProps, Label, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { checkIfDate } from '../../utils/dateUtils';
import { splitCamelCase } from '../../utils/stringUtils';

export interface IField<T> {
  field: keyof T;
  nestedField?: keyof any;
  label?: string;
  render?: (value: any) => JSX.Element;
  isFieldValid?: (value: any) => boolean;
  hideLabel?: boolean;
}

interface IHeaderInfoProps<T> {
  data: T;
  fields: IField<T>[];
  customFields?: Record<string, any>;
  stackProps?: IStackProps;
}

function GenericHeader<T>({ data, fields, customFields = {}, stackProps }: IHeaderInfoProps<T>): JSX.Element {
  const theme = getTheme();

  const contentId = useId();
  const labelId = `${contentId}-label`;

  return (
    <Stack grow tokens={{ childrenGap: 15 }} {...stackProps}>
      {fields.map(({ field, nestedField, label, render, isFieldValid, hideLabel }, index) => {
        const fieldValue = nestedField
          ? (data[field] as Record<string, any>)[nestedField as string]
          : field in customFields
          ? customFields[field as string]
          : data[field];
        return (
          (!isFieldValid || (isFieldValid && isFieldValid(fieldValue))) && (
            <Stack key={index}>
              {!hideLabel && (
                <Label id={labelId} htmlFor={contentId} aria-label={field as string}>
                  <Text style={{ color: theme.palette.neutralSecondary }}>
                    {label || splitCamelCase(field as string)}
                  </Text>
                </Label>
              )}

              <Text variant={'large'} id={contentId}>
                {render ? render(fieldValue) : checkIfDate<T>(fieldValue)}
              </Text>
            </Stack>
          )
        );
      })}
    </Stack>
  );
}

export default GenericHeader;
