import React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';

interface ConfirmationDialogProps {
  isVisible: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ isVisible, title, message, onConfirm, onCancel }) => {
  return (
    <Dialog
      hidden={!isVisible}
      onDismiss={onCancel}
      dialogContentProps={{
        title: title,
        subText: message,
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
      }}
    >
      <DialogFooter>
        <PrimaryButton onClick={onConfirm} text="Confirm" />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationDialog;
