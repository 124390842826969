import '../../styles/TopNav.css';

import { CommandBarButton, IContextualMenuItem, IContextualMenuProps, Stack } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { authProvider } from '../../auth/authConfiguration';
import { customButtonStyles, stackStyles } from '../../constants/styles';
import { tripsWikiLink } from '../../constants/constants';

const SmartNav: React.FC = () => {
  const currentAccount = authProvider.getAccount();
  const [isHelpHovered, setIsHelpHovered] = useState(false);

  const openWikiPage = () => {
    window.open(tripsWikiLink);
  };

  const generateMenuProps = useCallback(() => {
    const menuItems: IContextualMenuItem[] = [];

    const otherAccounts = authProvider.getOtherAccounts();

    otherAccounts.forEach((account) => {
      const accountMenuItem: IContextualMenuItem = {
        key: `otherAccount:${account.username}`,
        text: `${account.name}`,
        iconProps: { iconName: 'Contact' },
        onClick: () => {
          authProvider.loginWithAccount(account);
        },
        style: { height: '50px' },
      };

      menuItems.push(accountMenuItem);
    });

    menuItems.push({
      key: 'differentAccount',
      text: 'Sign in with a different account',
      iconProps: { iconName: 'Add' },
      onClick: () => {
        authProvider.loginWithDifferentAccount();
      },
      style: { height: '50px' },
    });

    menuItems.push({
      key: 'refreshAccount',
      text: `Refresh Login for ${currentAccount.username}`,
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        authProvider.refreshCurrentAccountToken();
      },
      style: { height: '50px' },
    });

    menuItems.push({
      key: 'log out',
      text: `Log out of ${currentAccount.username}`,
      iconProps: { iconName: 'WavingHand' },
      onClick: () => {
        authProvider.logoutCurrentAccount();
      },
      style: { height: '50px' },
    });

    const menuProps: IContextualMenuProps = {
      items: menuItems,
    };

    return menuProps;
  }, [currentAccount.username]);

  return (
    <div className="topnav">
      <div className="app-name">TRIPS Portal</div>
      <div>
        <Stack horizontal styles={stackStyles}>
          <CommandBarButton
            iconProps={{
              iconName: 'Help',
              style: {
                color: isHelpHovered ? 'black' : 'white',
              },
            }}
            onClick={openWikiPage}
            styles={customButtonStyles}
            title="Help"
            onMouseEnter={() => setIsHelpHovered(true)}
            onMouseLeave={() => setIsHelpHovered(false)}
          />
          <CommandBarButton
            toggle={true}
            text={currentAccount?.name}
            menuProps={generateMenuProps()}
            styles={customButtonStyles}
            title={`Signed in as ${currentAccount?.name}`}
          />
        </Stack>
      </div>
    </div>
  );
};

export default SmartNav;
