import { IDropdownOption, Link, Panel, PanelType } from '@fluentui/react';
import { ITDFTenantLeaseGroup } from '../api/api.types';
import { getAndFormatDate } from '../utils/dateUtils';
import CopyIconButton from '../components/common/CopyIconButton';
import { RenderTextOptions, ICreatePoolRequest, IPool, ISubscription, ISkuType, ISpoRouting, IProvisioningConfiguration } from '../constants/interfaces';
import { textLinkCopyContainerStyle, textLinkCopyTextStyle } from '../constants/styles';
import { useBoolean } from '@fluentui/react-hooks';
import { eActions, ReadAction } from '../constants/constants';

export const findTenant = (
  tenants: ITDFTenantLeaseGroup[] | undefined,
  tenantId: string
): ITDFTenantLeaseGroup | undefined => {
  return tenants?.find((t) => t.TenantId === tenantId);
};

export const renderDate = <T extends Record<string, any>>(object: T, dateKey: keyof T) => {
  const dateValue = object[dateKey];
  if (dateValue) {
    return getAndFormatDate(dateValue);
  }
};

export const renderTextLinkCopy = (text: string, options: RenderTextOptions = {}) => {
  const { url, showCopy = true } = options;

  return (
    <div style={textLinkCopyContainerStyle}>
      <div style={textLinkCopyTextStyle}>{url ? <Link href={url}>{text}</Link> : text}</div>
      {showCopy && <CopyIconButton copyText={text} />}
    </div>
  );
};

export const getStringValueOrNull = (value: string) => {
  return value.trim() === '' ? null : value;
};

export const serializeProvisioningConfigurations = (
  isParentProfileTenantBase: boolean,
  isCreatingTypeTenantPool: boolean,
  exchangeServiceInstance: string,
  subscriptions: ISubscription[] | null,
  tenantSpoRouting: ISpoRouting | null,
  selectedTenantSku: string | null,
  plannerServiceInstance: string,
  spoServiceInstance: string,
  poolName: string,
  dailyQuota: number | null,
  usageLocation: string
) => {
  const provisioningConfiguration = {
    ExchangeServiceInstance: getStringValueOrNull(exchangeServiceInstance),
    Subscriptions: subscriptions,
    TenantSpoRouting: tenantSpoRouting,
    TenantSku: selectedTenantSku,
    PlannerServiceInstance: getStringValueOrNull(plannerServiceInstance),
    SpoServiceInstance: getStringValueOrNull(spoServiceInstance),
    TargetPool: poolName,
    DailyQuota: dailyQuota,
    UsageLocation: getStringValueOrNull(usageLocation),
  };
  return isParentProfileTenantBase && isCreatingTypeTenantPool ? [JSON.stringify(provisioningConfiguration)] : [];
};

export const serializeCreatePoolRequest = (createPoolRequest: ICreatePoolRequest) => {
  return JSON.stringify(createPoolRequest);
};

export const deserializePoolData = (serializedData: string): IPool => {
  const requestData = JSON.parse(serializedData) as ICreatePoolRequest;
  return {
    PoolName: requestData.PoolName,
    State: '',
    ParentProfileName: requestData.ParentProfileName,
    TargetPoolSize: requestData.TargetPoolSize,
    Owners: requestData.Owners,
    Cost: requestData.Cost,
    ProvisionBufferHours: requestData.ProvisionBufferHours,
    IsReservable: requestData.IsReservable,
    IsDataProvisioned: requestData.IsDataProvisioned,
    IcmConnectorId: '',
    IsReusable: requestData.IsReusable,
    IsEnabled: false,
    ProvisioningConfigurations: requestData.ProvisioningConfigurations,
    HydrationTargets: requestData.HydrationTargets,
  };
};

export const RenderTextLinkPanel = (text: string, header?: string, panelContentComponent?: JSX.Element) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const handleLinkClick = () => {
    openPanel();
  };

  return (
    <>
      <Link onClick={handleLinkClick}>{text}</Link>
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        headerText={header ? header : ''}
      >
        {panelContentComponent && panelContentComponent}
      </Panel>
    </>
  );
};

export const getActionPastTense = (action: string): string => {
  if (eActions.includes(action)) {
    return `${action}d`;
  } else if (action == ReadAction) {
    return action;
  }

  return `${action}ed`;
};

export const getActionGerund = (action: string): string => {
  if (eActions.includes(action)) {
    return `${action.slice(0, -1)}ing`;
  } else {
    return `${action}ing`;
  }
};

export const toKebabCase = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};

export const mapSkuTypesToOptions = (skuTypes: ISkuType[]): IDropdownOption[] => {
  return skuTypes.map((sku) => ({
    key: sku.Name,
    text: sku.Name,
  }));
};

export const mapCountriesToOptions = (countries: string[]): IDropdownOption[] => {
  return countries.map((country) => ({
    key: country,
    text: country,
  }));
};
