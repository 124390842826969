const ppeFrontendClientId = process.env.REACT_APP_PPE_FRONTEND_CLIENT_ID as string;
const prdFrontendClientId = process.env.REACT_APP_PRD_FRONTEND_CLIENT_ID as string;

const ppeBackendClientId = process.env.REACT_APP_PPE_BACKEND_CLIENT_ID as string;
const prdBackendClientId = process.env.REACT_APP_PRD_BACKEND_CLIENT_ID as string;

const devBaseUrl = process.env.REACT_APP_DEV_BASE_URL as string;

export function getEnvironmentConfig() {
  if (window.location.origin.includes('tdff5ppe')) {
    return {
      frontendClientId: ppeFrontendClientId,
      backendClientId: ppeBackendClientId,
      baseUrl: window.location.origin.toString(),
    };
  } else if (window.location.origin.includes('tdff5prd')) {
    return {
      frontendClientId: prdFrontendClientId,
      backendClientId: prdBackendClientId,
      baseUrl: window.location.origin.toString(),
    };
  } else {
    return {
      frontendClientId: ppeFrontendClientId,
      backendClientId: ppeBackendClientId,
      baseUrl: devBaseUrl,
    };
  }
}
