import { ITheme, mergeStyleSets, Stack, Text, useTheme } from '@fluentui/react';
import React from 'react';

import CopyIconButton from './CopyIconButton';

type IPasswordFieldProps = {
  type: string;
  name: string;
  password: string;
};

const getStyles = (theme: ITheme) =>
  mergeStyleSets({
    dots: {
      ...theme.fonts.medium,
      color: theme.palette.neutralPrimary,
    },
  });

const PasswordField: React.FC<IPasswordFieldProps> = ({ type, name, password }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return password ? (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
      <Text className={styles.dots}>••••••••••</Text>
      <CopyIconButton copyText={password} />
    </Stack>
  ) : (
    <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 5 }}>
      <Text className={styles.dots}>N/A</Text>
    </Stack>
  );
};

export default PasswordField;
