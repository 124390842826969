import React, { useState } from 'react';
import { ActionButton, IIconProps, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react';
import { useMessageHandler } from '../../common/useMessageHandler';
import ExtendableMessageBar from '../../common/ExtendableMessageBar';
import { IUserAnnouncement } from '../../../constants/interfaces';
import { UserAnnouncementsTable } from './UserAnnouncements.table';
import {
  CreateUserAnnouncement,
  UpdateUserAnnouncement,
  tripsApi,
  useGetUserAnnouncementsQuery,
} from '../../../api/tripsApi';
import UserAnnouncementForm from './UserAnnouncementForm';
import { TagDescription } from '../../../constants/types';
import { useDispatch } from 'react-redux';

interface ManageUserAnnouncementsActionProps {}

const ManageUserAnnouncementsAction: React.FC<ManageUserAnnouncementsActionProps> = () => {
  const dispatch = useDispatch();

  const addIcon: IIconProps = { iconName: 'Add' };

  const { data: userAnnouncements } = useGetUserAnnouncementsQuery();

  const { defaultMessage, additionalMessage, messageType, resetMessage, showMessage } = useMessageHandler();

  const [creatingAnnouncement, setCreatingAnnouncement] = useState<boolean>(false);
  const [editingAnnouncement, setEditingAnnouncement] = useState<IUserAnnouncement | null>(null);

  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');
  const [announcementMessage, setAnnouncementMessage] = useState('');
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const toggleFormVisibility = () => setShowForm(!showForm);

  const handleTitleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    resetMessage();
    const updatedValue = newValue !== undefined ? newValue : (event.target as HTMLInputElement).value;
    setTitle(updatedValue);
  };

  const handleMessageChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    resetMessage();
    const updatedValue = newValue !== undefined ? newValue : (event.target as HTMLInputElement).value;
    setAnnouncementMessage(updatedValue);
  };

  const handleEndDateChange = (selectedDate: Date | null | undefined) => {
    resetMessage();
    setEndDate(selectedDate === null ? undefined : selectedDate);
  };

  const handleCancel = () => {
    setShowForm(false);
    setTitle('');
    setAnnouncementMessage('');
    setEndDate(undefined);
    setEditingAnnouncement(null);
    resetMessage();
  };

  const handleSubmitAnnouncement = async () => {
    resetMessage();
    setCreatingAnnouncement(true);

    const invalidateTags = (dispatchInvalidateTags: TagDescription[]) => {
      dispatchInvalidateTags.forEach((tag) => dispatch(tripsApi.util.invalidateTags([tag])));
    };

    try {
      let responseMessage = '';
      if (editingAnnouncement) {
        await UpdateUserAnnouncement(editingAnnouncement.ID, title, announcementMessage, endDate);
        responseMessage = `Announcement successfully updated.`;
      } else {
        await CreateUserAnnouncement(title, announcementMessage, endDate);
        responseMessage = `Announcement successfully created.`;
      }
      invalidateTags(['UserAnnouncements']);

      showMessage(responseMessage, MessageBarType.success);
      setShowForm(false);
      setTitle('');
      setAnnouncementMessage('');
      setEndDate(undefined);
      setEditingAnnouncement(null);
    } catch (error) {
      showMessage(
        `Error ${editingAnnouncement ? 'updating' : 'creating'} announcement...`,
        MessageBarType.error,
        `${error}`
      );
    }

    setCreatingAnnouncement(false);
  };

  const handleEditAnnouncement = (announcement: IUserAnnouncement) => {
    resetMessage();
    setTitle(announcement.Title);
    setAnnouncementMessage(announcement.Message);
    setEndDate(announcement.EndDate);
    setEditingAnnouncement(announcement);
    setShowForm(true);
  };

  return (
    <>
      <Stack>
        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 155 }}>
          <div style={{ marginTop: '1rem' }}>
            <ActionButton
              text={'New Announcement'}
              iconProps={addIcon}
              styles={{ root: { width: '200px' } }}
              onClick={toggleFormVisibility}
            />
          </div>
        </Stack>
        {userAnnouncements && userAnnouncements.length > 0 ? (
          <UserAnnouncementsTable userAnnouncements={userAnnouncements} onEditAnnouncement={handleEditAnnouncement} />
        ) : (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            styles={{ root: { marginTop: '10px' } }}
          >
            There are no announcements at this time. Create a new announcement below.
          </MessageBar>
        )}
        {showForm && (
          <UserAnnouncementForm
            title={title}
            announcementMessage={announcementMessage}
            endDate={endDate}
            handleTitleChange={handleTitleChange}
            handleMessageChange={handleMessageChange}
            handleEndDateChange={handleEndDateChange}
            handleSubmit={handleSubmitAnnouncement}
            handleCancel={handleCancel}
            formMode={editingAnnouncement ? 'edit' : 'create'}
          />
        )}
        {creatingAnnouncement && <Spinner label="Creating announcement..." ariaLive="assertive" />}
        {defaultMessage.length > 0 && messageType !== null && (
          <ExtendableMessageBar
            messageType={messageType}
            shortMessage={defaultMessage}
            additionalMessage={additionalMessage}
          />
        )}
      </Stack>
    </>
  );
};

export default ManageUserAnnouncementsAction;
