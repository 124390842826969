import { DefaultEffects, IStackProps, Stack } from '@fluentui/react';
import React from 'react';

type ICardProps = IStackProps;

const Card: React.FC<ICardProps> = (props) => {
  return (
    <Stack
      tokens={{ childrenGap: 10 }}
      styles={{
        ...props.styles,
        root: {
          boxShadow: DefaultEffects.elevation4,
          backgroundColor: 'white',
          padding: 15,
        },
      }}
      {...props}
    >
      {props.children}
    </Stack>
  );
};

export default Card;
