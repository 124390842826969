import { Breadcrumb, Stack } from '@fluentui/react';
import React from 'react';

import Card from '../../common/Card';
import GenericHeader from '../../common/GenericHeader';
import { ITDFTenantLeaseGroup } from '../../../api/api.types';
import PasswordField from '../../common/PasswordField';
import { CustomSeparator } from '../../common/Separators';

interface ILeasedTenantProps {
  leasedTenant: ITDFTenantLeaseGroup;
}

const LeasedTenantHeader: React.FC<ILeasedTenantProps> = ({ leasedTenant }) => {
  return (
    <>
      <Breadcrumb
        items={[
          {
            text: `Tenants`,
            key: 'myTenants',
            href: `/`,
          },
          {
            text: leasedTenant.TenantId,
            key: 'tenantId',
            isCurrentItem: true,
          },
        ]}
        ariaLabel="Breadcrumbs with a button taking you back to main Home Page and a label for the current Tenant Page."
      />
      <Card tokens={{ maxWidth: 1300 }}>
        <CustomSeparator text="Tenant" />
        <Stack tokens={{ childrenGap: 20 }}>
          <GenericHeader
            data={leasedTenant}
            fields={[{ field: 'TenantId' }]}
            stackProps={{ horizontal: true, tokens: { childrenGap: 50 } }}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 20 }}>
          <GenericHeader
            data={leasedTenant}
            fields={[{ field: 'TestDataProfileName' }, { field: 'ExpirationUtc' }, { field: 'MaxExpirationUtc' }]}
            stackProps={{ horizontal: true, tokens: { childrenGap: 50 } }}
          />
        </Stack>
        <CustomSeparator text="Admin" />
        <Stack tokens={{ childrenGap: 20 }}>
          <GenericHeader
            data={leasedTenant}
            fields={[
              { field: 'AdminUserPrincipalName' },
              {
                field: 'AdminPassword',
                render: (password) => <PasswordField type="Admin" name="AdminPassword" password={password} />,
              },
            ]}
            stackProps={{ horizontal: true, tokens: { childrenGap: 50 } }}
          />
        </Stack>
      </Card>
    </>
  );
};

export default LeasedTenantHeader;
