import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EndSessionRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { AuthError } from 'msal';
import { AuthenticationState } from 'react-aad-msal';

import { tripsApiScopes } from './authConfiguration';

// Type definitions for Handlers that will listen to
// internal state changes within AuthenticationProvider
type AuthenticationStateHandler = (state: AuthenticationState) => void;
type ErrorHandler = (error: AuthError | undefined) => void;

const LAST_SIGNED_IN_KEY = 'LAST_SIGNED_IN_KEY';

export default class AuthenticationProvider extends PublicClientApplication {
  public authenticationState: AuthenticationState;

  // Private properties
  private currentAccount: AccountInfo | undefined;
  private apiRequest: RedirectRequest;
  private error: AuthError | undefined;

  // Listeners
  private onAuthenticationStateHandlers = new Set<AuthenticationStateHandler>();
  private onErrorHandlers = new Set<ErrorHandler>();

  /**
   * Initialize and set up the AuthenticationProvider
   *
   * @param msalConfig MSAL Configuration for the provider to utilize
   */
  constructor(msalConfig: Configuration) {
    // Initialize the MSAL instance (PublicClientApplication)
    super(msalConfig);

    this.authenticationState = AuthenticationState.Unauthenticated;

    // Default scopes that we will request when signing in (for the purposes
    // of the console, this creates a token who's audience is our backend API)
    this.apiRequest = {
      scopes: tripsApiScopes,
    };
  }

  /**
   * For the current user, get a console API token
   */
  async getApiToken(): Promise<AuthenticationResult> {
    if (this.currentAccount === undefined) {
      throw Error('Not logged in, make sure you are logged in before executing this method.');
    }

    const silentLoginRequest: SilentRequest = {
      ...this.apiRequest,
      account: this.currentAccount,
    };

    return await this.acquireTokenSilent(silentLoginRequest);
  }

  /**
   * Gets the current account
   */
  getAccount(): AccountInfo {
    if (this.currentAccount === undefined) {
      throw Error('Not logged in, make sure you are logged in before executing this method.');
    }

    return this.currentAccount;
  }

  /**
   * Gets the other accounts that MSAL has cached in the LocalStorage, and
   * excludes the current account that is logged in.
   */
  getOtherAccounts(): AccountInfo[] {
    const allAccounts = this.getAllAccounts();

    const otherAccounts = allAccounts.filter((account) => {
      return account.localAccountId !== this.currentAccount?.localAccountId;
    });

    return otherAccounts;
  }

  /**
   * Used by AuthenticationProvider.login() to determine which accounts that
   * MSAL has cached in LocalStorage, and then which of those was the account
   * that was last signed in, and signs in that account first.
   */
  getLastSignedInAccount(): AccountInfo | undefined {
    const allAccounts = this.getAllAccounts();

    // If there are no accounts in cache, return
    if (allAccounts.length === 0) return undefined;

    const lastSignedInUsername = localStorage.getItem(LAST_SIGNED_IN_KEY);

    if (lastSignedInUsername) {
      return allAccounts.find((account) => account.username === lastSignedInUsername);
    } else {
      return allAccounts[0];
    }
  }

  async login(): Promise<void> {
    this.setAuthenticationState(AuthenticationState.InProgress);

    // First check to see if we are in the process of handling a redirect
    const tokenResponse = await this.handleRedirectPromise();
    if (tokenResponse !== null) {
      this.processLoginTokenResponse(tokenResponse);
      return;
    }

    // If we are here, attempt to get current account from cache and perform a silent
    // SSO for that account. If the ssoSilent() call fails, trySsoSilet() will initiate
    // a loginRedirect().
    const lastSignedInAccount = this.getLastSignedInAccount();
    if (lastSignedInAccount) {
      return await this.trySilentLogin(lastSignedInAccount);
    }

    // If we are here, there were no accounts form cache or this.account was not defined,
    // so perform the default redirect login, the user will be expected to choose an account
    // to sign in with
    this.loginRedirect(this.apiRequest);
  }

  /**
   * When executed, will set the currentAccount to undefined, and then prompt the redirect
   */
  async loginWithDifferentAccount(): Promise<void> {
    this.currentAccount = undefined;
    this.setAuthenticationState(AuthenticationState.InProgress);

    // Redirect user to AAD account selection page
    const loginRequest: RedirectRequest = {
      ...this.apiRequest,
      prompt: 'select_account',
    };

    this.loginRedirect(loginRequest);
  }

  /**
   * Given an account object, sign in and set that as the primary account
   *
   * @param account The account to sign in to
   */
  async loginWithAccount(account: AccountInfo): Promise<void> {
    this.currentAccount = undefined;
    this.setAuthenticationState(AuthenticationState.InProgress);

    const silentRequest: SsoSilentRequest = {
      ...this.apiRequest,
      account: account,
    };

    const tokenResponse = await this.ssoSilent(silentRequest);
    this.processLoginTokenResponse(tokenResponse);
  }

  /**
   * When invoked, takes the currentAccount object and re-completes
   * a silent SSO request to fetch a new token from AAD. Typically this
   * is useful when user is wanting to check if their elevation status
   * has propagated
   */
  async refreshCurrentAccountToken(): Promise<void> {
    this.setAuthenticationState(AuthenticationState.InProgress);

    if (!this.currentAccount) {
      console.error('There is no account currently signed in, unable to refresh credentials.');
      return;
    }

    const silentRequest: SsoSilentRequest = {
      ...this.apiRequest,
      account: this.currentAccount,
    };

    const tokenResponse = await this.ssoSilent(silentRequest);
    this.processLoginTokenResponse(tokenResponse);
  }

  /**
   * Logout of the current account that is signed in, after logout, will be redirected
   * to the application's home.
   */
  logoutCurrentAccount(): void {
    const logoutRequest: EndSessionRequest = {
      account: this.currentAccount,
    };

    this.logoutRedirect(logoutRequest);
  }

  /**
   * Upon conclusion of a login request, set the currentAccount, update the LAST_SIGNED_IN_KEY
   * in LocalStorage, and then set the authentication state.
   *
   * @param tokenResponse The token response from a silent/redirect login request
   */
  private processLoginTokenResponse = (tokenResponse: AuthenticationResult) => {
    if (tokenResponse.account === null) {
      throw new Error('Account should not be null, this method processLoginTokenResponse should not have been called.');
    }

    this.currentAccount = tokenResponse.account;

    localStorage.setItem(LAST_SIGNED_IN_KEY, this.currentAccount.username);

    this.setAuthenticationState(AuthenticationState.Authenticated);
  };

  /**
   * Given an account, try to acquire it's token silently. If the Account's session
   * is expired, automatically initiate a redirect authentication process.
   *
   * @param account The account
   */
  private trySilentLogin = async (account: AccountInfo) => {
    try {
      const silentRequest: SilentRequest = {
        ...this.apiRequest,
        account: account,
      };

      const tokenResponse = await this.acquireTokenSilent(silentRequest);

      this.processLoginTokenResponse(tokenResponse);
    } catch (error) {
      this.logger.info(typeof error);
      this.loginRedirect(this.apiRequest);
    }
  };

  private setAuthenticationState = (state: AuthenticationState): AuthenticationState => {
    if (this.authenticationState !== state) {
      this.authenticationState = state;

      this.onAuthenticationStateHandlers.forEach((listener) => listener(state));
    }

    return this.authenticationState;
  };

  private setError = (error: AuthError | undefined) => {
    this.error = error ? { ...error } : undefined;
    this.onErrorHandlers.forEach((listener) => listener(error));
    return { ...this.error };
  };

  public registerAuthenticationStateHandler = (listener: AuthenticationStateHandler): void => {
    this.onAuthenticationStateHandlers.add(listener);
    listener(this.authenticationState);
  };

  public unregisterAuthenticationStateHandler = (listener: AuthenticationStateHandler): void => {
    this.onAuthenticationStateHandlers.delete(listener);
  };

  public registerErrorHandler = (listener: ErrorHandler): void => {
    this.onErrorHandlers.add(listener);
    listener(this.error);
  };

  public unregisterErrorHandler = (listener: ErrorHandler): void => {
    this.onErrorHandlers.delete(listener);
  };
}
