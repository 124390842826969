/**
 * Given a string in camel case or pascal case, return the string with
 * spaces for each case of capitalization, and then capitalizes the first letter
 *
 *   e.g. "totalTasks" becomes "Total Tasks"
 *
 * @param field a string, in any casing
 * @returns the string, split by the capital letters
 */
export const splitCamelCase = (field: string): string => {
  if (field === '') return field;

  return (
    field
      .replace(/\s+/g, '')
      .split(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])|(?<=[a-z])(?=[0-9])/g)
      ?.map((word) => word[0].toLocaleUpperCase() + word.slice(1))
      .join(' ') || field
  );
};

type ObjectWithRoles = {
  roles: string[];
};

/**
 * Type guard to check if untyped object contains a `roles` property.
 *
 * @param object object to check
 * @returns boolean indicating whether object has Roles property
 */
export function isObjectWithRoles(object: unknown): object is ObjectWithRoles {
  const castedObject = object as ObjectWithRoles;
  return castedObject.roles !== undefined && castedObject.roles.values !== undefined;
}

/**
 * The roles present on the token.
 *
 * @param token token
 * @returns the array of roles
 */
export const parseRolesFromToken = (token: string): string[] => {
  // First parse the token into a RAW JSON object
  const payload = token.split('.')[1];
  try {
    const parsedToken = JSON.parse(atob(payload));

    if (isObjectWithRoles(parsedToken)) {
      return parsedToken.roles;
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

/**
 * Generates a mailto link for emailing the console feature crew about help with acquiring a specific role
 */
export const generateRoleHelpMailto = (userName: string, role: string): string => {
  const to = `m365consolecrew@microsoft.com`;

  const subject = encodeURI(`[${role}] Need Help Acquiring Role`);

  const body = `Hello Console Feature Crew,

I am signed in to the console as ${userName} and am having issues acquiring the ${role} role.`;

  return `mailto:${to}?subject=${subject}&body=${encodeURIComponent(body)}`;
};

/**
 * Converts minutes to hours and minutes.
 * @example
 * 61 min -> 1 hours 1 min
 * @param totalMinutes the total number of minutes
 * @returns the hours and minutes
 */
export const toHoursMinutesSeconds = (totalMinutes: number): string => {
  const minutes = (totalMinutes % 60).toFixed(1);
  const hours = Math.floor(totalMinutes / 60);

  if (totalMinutes < 1) {
    return `${(totalMinutes * 60).toFixed(1)} seconds`;
  }

  if (hours === 0) {
    return `${minutes} min`;
  } else if (hours === 1) {
    return `${hours} hour ${minutes} min`;
  } else {
    return `${hours} hours ${minutes} min`;
  }
};
