import { Text } from '@fluentui/react';
import React from 'react';
import AdminActionCard from '../Pool/AdminActions/AdminActionCard';
import Layout from '../common/Layout';
import ManageUserAnnouncementsAction from './UserAnnouncements/ManageUserAnnouncementsAction';
import { CreatePool, useGetAssetPoolNamesQuery } from '../../api/tripsApi';
import CreateOrUpdateAssetPool from './createPool/CreateOrUpdateAssetPool';
import ManageUpdateAssetPool from './createPool/ManageUpdateAssetPool';
import { CreateAction } from '../../constants/constants';
import { GetAssetPoolByName } from '../../api/helpersApi';

const AdminActions: React.FC = () => {
  const { data: poolNames } = useGetAssetPoolNamesQuery();
  return (
    <>
      <Layout>
        <Text variant={'xLarge'}>Admin Actions</Text>
        <AdminActionCard
          title="Manage User Announcements"
          description="Create, update or delete user announcements."
          panelContentComponent={ManageUserAnnouncementsAction}
        />
        <AdminActionCard
          title="Create Asset Pool"
          description="Create Asset Pool."
          panelContentComponent={({}) => {
            return <CreateOrUpdateAssetPool managePool={CreatePool} action={CreateAction} />;
          }}
        />
        <AdminActionCard
          title="Update Asset Pool"
          description="Update Asset Pool."
          panelContentComponent={({}) => {
            return <ManageUpdateAssetPool poolNames={poolNames || []} getAssetPoolByName={GetAssetPoolByName} />;
          }}
        />
      </Layout>
    </>
  );
};

export default AdminActions;
