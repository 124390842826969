import { Spinner, Stack } from '@fluentui/react';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { useDispatch } from 'react-redux';

import { userRoleStateReset, userRoleStateUpdated } from '../redux/slices/appSlice';
import { parseRolesFromToken } from '../utils/stringUtils';
import AuthenticationProvider from './AuthenticationProvider';
import { UserRoleContext, UserRoleContextType } from './UserRoleContext';

export type IAuthenticationProps = {
  provider: AuthenticationProvider;
  forceLogin?: boolean;
};

export const Authentication: React.FC<PropsWithChildren<IAuthenticationProps>> = ({
  provider,
  forceLogin,
  children,
}) => {
  const [userRoles, setUserRoles] = useState<UserRoleContextType>({
    isAdmin: false,
    isUser: false,
    isTester: false,
  });

  const dispatch = useDispatch();
  const [authenticationState, setAuthenticationState] = useState(provider.authenticationState);

  const login = useCallback(() => {
    provider.login();
  }, [provider]);

  // On mount, register the authenticationState hook with the new Auth Provider
  // so that the render can know what state of authentication we are in
  useEffect(() => {
    provider.registerAuthenticationStateHandler(setAuthenticationState);

    if (forceLogin && provider.authenticationState === AuthenticationState.Unauthenticated) login();

    return () => {
      provider.unregisterAuthenticationStateHandler(setAuthenticationState);
    };
  }, [provider, forceLogin, login]);

  useEffect(() => {
    /**
     * Function that when invoked attempts to acquire the user's current token
     * and extract the roles from that token. Once the roles are extracted, the store
     * is updated with the updated user role state
     */
    async function extractRolesFromTokenAndUpdateUserStatus() {
      const token = await provider.getApiToken();

      const roles = parseRolesFromToken(token.accessToken);

      const isUser = roles.includes('TDFUser');
      const isAdmin = roles.includes('TDFAdmin');
      const isTester = roles.includes('Tester');
      dispatch(userRoleStateUpdated({ isAdmin, isUser, isTester }));

      setUserRoles({ isAdmin, isUser, isTester });
    }

    // If the user is authenticated, extract roles. Otherwise reset the role state.
    if (authenticationState === AuthenticationState.Authenticated) {
      extractRolesFromTokenAndUpdateUserStatus();
    } else {
      dispatch(userRoleStateReset());
    }
  }, [authenticationState, dispatch, provider]);

  switch (authenticationState) {
    case AuthenticationState.Unauthenticated:
      return (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { height: '100vh' } }}>
          <h3>You are not signed in...</h3>
        </Stack>
      );
    case AuthenticationState.InProgress:
      return (
        <Stack verticalAlign="center" styles={{ root: { height: '100vh' } }}>
          <Spinner label="Authenticating..." />
        </Stack>
      );
    case AuthenticationState.Authenticated:
      return <UserRoleContext.Provider value={userRoles}>{children}</UserRoleContext.Provider>;
    default:
      return <React.Fragment />;
  }
};
