import {
  Dropdown,
  IDropdownOption,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useMessageHandler } from '../../common/useMessageHandler';
import TenantSkuSelector from './TenantSkuSelector';
import {
  TenantBase,
  TenantPool,
  assetPoolType,
  ReadAction,
  CreateAction,
  AssetPoolTooltipContent,
} from '../../../constants/constants';
import ExtendableMessageBar from '../../common/ExtendableMessageBar';
import {
  getActionGerund,
  getActionPastTense,
  mapCountriesToOptions,
  mapSkuTypesToOptions,
  serializeProvisioningConfigurations,
} from '../../../functions/helpers';
import { HydrationTargetColumns } from './HydrationTargets.columns';
import {
  HydrationTarget,
  IPool,
  IProvisioningConfiguration,
  ISpoRouting,
  ISubscription,
} from '../../../constants/interfaces';
import ConfirmationWithInputDialog from '../../common/ConfirmationWithInputDialog';
import { GetAssetPoolByName } from '../../../api/helpersApi';
import ItemList from '../../common/ItemList';
import SubscriptionsColumns from './Subscriptions.columns';
import InputTitle from '../../common/InputTitle';
import { useGetCountriesQuery, useGetSkuTypesQuery } from '../../../api/metadataApi';
import { dropdownStyles } from '../../../constants/styles';
import ToggleWithTooltip from '../../common/ToggleWithTooltip';

export interface ICreateOrUpdateAssetPoolProps {
  managePool?: (
    poolName: string,
    parentProfile: string,
    targetPoolSize: number,
    cost: number,
    provisionBufferHours: number,
    isReservable: boolean,
    isDataProvisioned: boolean,
    isReusable: boolean,
    provisioningConfigurations: string[],
    hydrationTargets: HydrationTarget[],
    userInput?: string
  ) => Promise<void>;
  action: string;
  assetPool?: IPool;
  skipDialog?: boolean;
  readOnly?: boolean;
  onRequestAdded?: () => void;
}

const CreateOrUpdateAssetPool: React.FC<ICreateOrUpdateAssetPoolProps> = ({
  managePool,
  action,
  assetPool,
  skipDialog = true,
  readOnly = false,
  onRequestAdded,
}) => {
  const { defaultMessage, additionalMessage, messageType, resetMessage, showMessage } = useMessageHandler();

  let poolProvisioningConfig: IProvisioningConfiguration | null = null;

  if (assetPool) {
    if (
      assetPool.ProvisioningConfigurations &&
      assetPool.ProvisioningConfigurations.length > 0 &&
      assetPool.ProvisioningConfigurations[0] !== ''
    ) {
      poolProvisioningConfig = JSON.parse(assetPool.ProvisioningConfigurations[0]); // ANNA: double check we only care about 1 provisioning config
      if (poolProvisioningConfig!.DailyQuota) dailyQuota = poolProvisioningConfig!.DailyQuota;
      if (poolProvisioningConfig!.PlannerServiceInstance)
        plannerServiceInstance = poolProvisioningConfig!.PlannerServiceInstance;
      if (poolProvisioningConfig!.SpoServiceInstance) spoServiceInstance = poolProvisioningConfig!.SpoServiceInstance;
      if (poolProvisioningConfig!.TenantSku) selectedTenantSku = poolProvisioningConfig!.TenantSku;
      if (poolProvisioningConfig!.Subscriptions) subscriptions = poolProvisioningConfig!.Subscriptions;
      if (poolProvisioningConfig!.TenantSpoRouting) tenantSpoRouting = poolProvisioningConfig!.TenantSpoRouting;
      if (poolProvisioningConfig!.ExchangeServiceInstance)
        exchangeServiceInstance = poolProvisioningConfig!.ExchangeServiceInstance;
      if (poolProvisioningConfig!.UsageLocation) usageLocation = poolProvisioningConfig!.UsageLocation;
    }
  }

  var [poolName, setPoolName] = useState(assetPool?.PoolName ?? '');
  var [parentProfile, setParentProfile] = useState(assetPool?.ParentProfileName ?? TenantBase);
  var [targetPoolSize, setTargetPoolSize] = useState<number>(assetPool?.TargetPoolSize ?? 1);
  const [isTargetPoolSizeValid, setIsTargetPoolSizeValid] = useState<boolean>(true);
  const [targetPoolSizeErrorMessage, setTargetPoolSizeErrorMessage] = useState('');
  var [cost, setCost] = useState<number>(assetPool?.Cost ?? 1);
  const [isCostValid, setIsCostValid] = useState<boolean>(true);
  const [costErrorMessage, setCostErrorMessage] = useState('');
  var [provisionBufferHours, setProvisionBufferHours] = useState<number>(assetPool?.ProvisionBufferHours ?? 0);
  const [isProvisionBufferHoursValid, setIsProvisionBufferHoursValid] = useState<boolean>(true);
  const [provisionBufferHoursErrorMessage, setProvisionBufferHoursErrorMessage] = useState('');
  var [dailyQuota, setDailyQuota] = useState<number | null>(poolProvisioningConfig?.DailyQuota ?? 1);
  const [isDailyQuotaValid, setIsDailyQuotaValid] = useState<boolean>(true);
  const [dailyQuotaErrorMessage, setDailyQuotaErrorMessage] = useState('');
  var [isReservable, setIsReservable] = useState<boolean>(assetPool?.IsReservable ?? true);
  var [isDataProvisioned, setIsDataProvisioned] = useState<boolean>(assetPool?.IsDataProvisioned ?? false);
  var [isReusable, setIsReusable] = useState<boolean>(assetPool?.IsReusable ?? true);
  var [exchangeServiceInstance, setExchangeServiceInstance] = useState(
    poolProvisioningConfig ? poolProvisioningConfig.ExchangeServiceInstance ?? '' : '<PROD>'
  );
  var [plannerServiceInstance, setPlannerServiceInstance] = useState(
    poolProvisioningConfig?.PlannerServiceInstance ?? ''
  );
  var [spoServiceInstance, setSpoServiceInstance] = useState(poolProvisioningConfig?.SpoServiceInstance ?? '');
  var [usageLocation, setUsageLocation] = useState(poolProvisioningConfig?.UsageLocation ?? '');
  var [selectedTenantSku, setSelectedTenantSku] = useState<string | null>(poolProvisioningConfig?.TenantSku ?? null);
  var [subscriptions, setSubscriptions] = React.useState<ISubscription[]>(poolProvisioningConfig?.Subscriptions ?? []);
  var [newSubscription, setNewSubscription] = useState<ISubscription>({ SkuType: '', LicenseCount: 1 });
  const [subscriptionLicenseCountErrorMessage, setSubscriptionLicenseCountErrorMessage] = useState('');
  const [isSubscriptionLicenseCountValid, setIsSubscriptionLicenseCountValid] = useState<boolean>(true);
  const [submittingManagePoolRequest, setSubmittingManagePoolRequest] = useState<boolean>(false);
  const [selectedAssetPoolTypeKey, setSelectedAssetPoolTypeKey] = useState<string | null>(TenantPool); //ANNA: update with account or tenant pool?
  var [tenantSpoRouting, setTenantSpoRouting] = React.useState<ISpoRouting | null>(
    poolProvisioningConfig?.TenantSpoRouting ?? null
  );
  var [hydrationTargets, setHydrationTargets] = React.useState<HydrationTarget[]>(assetPool?.HydrationTargets ?? []);
  var [newHydrationTarget, setNewHydrationTarget] = useState<HydrationTarget>({ ProfileName: '', Count: 1 });
  const [newHydrationTargetCountErrorMessage, setNewHydrationTargetCountErrorMessage] = useState('');
  const [isNewHydrationTargetCountValid, setIsNewHydrationTargetCountValid] = useState<boolean>(true);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>('');
  const [skuSelectedOption, setSkuSelectedOption] = useState<string>(
    poolProvisioningConfig?.Subscriptions && poolProvisioningConfig.Subscriptions.length > 0
      ? 'subscription'
      : 'tenantSku'
  );

  const provisioningConfigurationStrings = serializeProvisioningConfigurations(
    parentProfile == TenantBase,
    selectedAssetPoolTypeKey == TenantPool,
    exchangeServiceInstance,
    subscriptions,
    tenantSpoRouting,
    selectedTenantSku,
    plannerServiceInstance,
    spoServiceInstance,
    poolName,
    dailyQuota,
    usageLocation
  );

  const { data: tenantSkus } = useGetSkuTypesQuery(true);
  const { data: skuTypes } = useGetSkuTypesQuery(false);
  const { data: countries } = useGetCountriesQuery();

  const [tenantSkuOptions, setTenantSkuOptions] = useState<IDropdownOption[]>([]);
  const [skuTypeOptions, setSkuTypeOptions] = useState<IDropdownOption[]>([]);
  const [countryOptions, setCountryOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    if (tenantSkus) {
      setTenantSkuOptions(mapSkuTypesToOptions(tenantSkus));
    }
    if (skuTypes) {
      setSkuTypeOptions(mapSkuTypesToOptions(skuTypes));
    }
    if (countries) {
      setCountryOptions(mapCountriesToOptions(countries));
    }
  }, [tenantSkus, skuTypes, countries]);

  const handleSubmit = async () => {
    resetMessage();
    if (skipDialog) {
      await handleDialogSubmit();
    } else {
      setIsDialogVisible(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogVisible(false);
  };

  const handleUserInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setUserInput(newValue || '');
  };

  const handleSkuOptionChange = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    const optionKey = checked ? 'tenantSku' : 'subscription';
    setSkuSelectedOption(optionKey);
    if (optionKey === 'tenantSku') {
      handleSubscriptionChange([]);
    } else {
      handleTenantSkuChange(null, undefined);
    }
  };

  const handleDialogSubmit = async () => {
    setIsDialogVisible(false);
    setSubmittingManagePoolRequest(true);

    const isTenantSkuSelected = skuSelectedOption === 'tenantSku';
    const isSubscriptionSelected = skuSelectedOption === 'subscription';

    if (
      managePool &&
      poolName !== '' &&
      parentProfile != '' &&
      isTargetPoolSizeValid &&
      isCostValid &&
      isProvisionBufferHoursValid &&
      (parentProfile !== 'TenantBase' ||
        (isTenantSkuSelected && selectedTenantSku) ||
        (isSubscriptionSelected && subscriptions.length > 0))
    ) {
      try {
        await managePool(
          poolName,
          parentProfile,
          targetPoolSize,
          cost,
          provisionBufferHours,
          isReservable,
          isDataProvisioned,
          isReusable,
          provisioningConfigurationStrings,
          hydrationTargets,
          userInput
        );

        let successMessage = `Pool ${getActionPastTense(action)} Successfully.`;

        if (action === CreateAction) {
          try {
            let createdPool = await GetAssetPoolByName(poolName);
            successMessage += ` <a href="/pools/${createdPool.PoolName}/testassets">View Pool</a>`;
          } catch (error) {
            console.error('Error fetching pool: ', error);
          }
        }
        showMessage(successMessage, MessageBarType.success);
        if (onRequestAdded) {
          onRequestAdded();
        }
      } catch (error) {
        showMessage(`Error when attempting to ${action} pool...`, MessageBarType.error, `${error}`);
      }
    } else {
      showMessage(`Please fill in all required fields.`, MessageBarType.error);
    }
    setSubmittingManagePoolRequest(false);
  };

  const handleTextChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: (value: string) => void,
    newValue?: string
  ): void => {
    resetMessage();
    const updatedValue = newValue !== undefined ? newValue.trim() : (event.target as HTMLInputElement).value;
    setValue(updatedValue);
  };

  const handleNumberInputChange = (
    setErrorMessage: (message: string) => void,
    setIsValid: (isValid: boolean) => void,
    setValue: (value: number) => void,
    newValue?: string,
    minValidValue = 0
  ): void => {
    resetMessage();
    if (newValue != null && parseInt(newValue) >= minValidValue) {
      setErrorMessage('');
      setIsValid(true);
      setValue(parseInt(newValue));
    } else {
      setErrorMessage('Please enter a value greater than or equal to: ' + minValidValue);
      setValue(minValidValue);
    }
  };

  const handleTenantSkuChange = (event: React.FormEvent<HTMLDivElement> | null, option?: IDropdownOption) => {
    setSelectedTenantSku(option ? (option.key as string) : 'Unknown');
  };

  const handleSubscriptionChange = (newSubscriptions: ISubscription[]) => {
    setSubscriptions(newSubscriptions);
  };

  const handleAssetPoolTypeChange = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    let key: string | null = option ? (option.key as string) : null;

    if (key != null) {
      setParentProfile(key + 'Base');
    }

    setSelectedAssetPoolTypeKey(key);
  };

  const handleAddingHydrationTargets = (newValue: HydrationTarget) => {
    const existingProfiles = hydrationTargets.map((target) => target.ProfileName);
    if (!existingProfiles.includes(newValue.ProfileName) && newValue.ProfileName.trim() != '') {
      setHydrationTargets((hydrationTargets) => [...hydrationTargets, newValue]);
      setNewHydrationTarget({ ProfileName: '', Count: 1 });
    }
  };

  const handleAddingSubscriptions = (newValue: ISubscription) => {
    const existingSubscriptions = subscriptions.map((target) => target.SkuType);
    if (!existingSubscriptions.includes(newValue.SkuType) && newValue.SkuType.trim() != '') {
      setSubscriptions((hydrationTargets) => [...hydrationTargets, newValue]);
      setNewSubscription({ SkuType: '', LicenseCount: 1 });
    }
  };

  const handleUsageLocationChange = (_: React.FormEvent<HTMLDivElement> | null, option?: IDropdownOption) => {
    setUsageLocation(option ? (option.key as string) : '');
  };

  return (
    <>
      <Stack>
        <Stack>
          <InputTitle title="Type of asset pool" />
          <Dropdown
            id="type-of-asset-pool"
            options={assetPoolType}
            selectedKey={selectedAssetPoolTypeKey}
            onChange={handleAssetPoolTypeChange}
            disabled={readOnly}
            styles={dropdownStyles}
          />
        </Stack>
        <Stack>
          <InputTitle title="Pool Name" />
          <TextField
            id="pool-name"
            value={poolName}
            onChange={(event, newValue) => handleTextChange(event, setPoolName, newValue)}
            required={true}
            disabled={readOnly}
          />
        </Stack>
        <Stack>
          <InputTitle title="Parent Profile" tooltipContent={AssetPoolTooltipContent.parentProfile} />
          <TextField
            id="parent-profile"
            value={parentProfile}
            onChange={(event, newValue) => handleTextChange(event, setParentProfile, newValue)}
            required={true}
            disabled={readOnly}
          />
        </Stack>
        <Stack>
          <InputTitle title="Pool Size" tooltipContent={AssetPoolTooltipContent.poolSize} />
          <TextField
            id="pool-size"
            type="number"
            value={targetPoolSize.toString()}
            min={1}
            onChange={(event, newValue) =>
              handleNumberInputChange(
                setTargetPoolSizeErrorMessage,
                setIsTargetPoolSizeValid,
                setTargetPoolSize,
                newValue
              )
            }
            errorMessage={targetPoolSizeErrorMessage}
            disabled={readOnly}
          />
        </Stack>
        <Stack>
          <InputTitle title="Cost" tooltipContent={AssetPoolTooltipContent.cost} />
          <TextField
            id="cost"
            type="number"
            value={cost.toString()}
            min={0}
            onChange={(event, newValue) =>
              handleNumberInputChange(setCostErrorMessage, setIsCostValid, setCost, newValue)
            }
            errorMessage={costErrorMessage}
            disabled={readOnly}
          />
        </Stack>
        <Stack>
          <InputTitle title="Provision Buffer Hours" tooltipContent={AssetPoolTooltipContent.provisionBufferHours} />
          <TextField
            id="provision-buffer-hours"
            type="number"
            value={provisionBufferHours.toString()}
            min={0}
            onChange={(event, newValue) =>
              handleNumberInputChange(
                setProvisionBufferHoursErrorMessage,
                setIsProvisionBufferHoursValid,
                setProvisionBufferHours,
                newValue
              )
            }
            errorMessage={provisionBufferHoursErrorMessage}
            disabled={readOnly}
          />
        </Stack>
        <ToggleWithTooltip
          label="isReservable"
          checked={isReservable}
          onChange={(ev, checked) => {
            setIsReservable(checked || false);
          }}
          disabled={readOnly}
        />
        <ToggleWithTooltip
          label="isDataProvisioned"
          checked={isDataProvisioned}
          onChange={(ev, checked) => {
            setIsDataProvisioned(checked || false);
          }}
          disabled={readOnly}
        />
        <ToggleWithTooltip
          label="isReusable"
          checked={isReusable}
          onChange={(ev, checked) => {
            setIsReusable(checked || false);
          }}
          disabled={readOnly}
        />
        {parentProfile == TenantBase && selectedAssetPoolTypeKey == TenantPool && (
          <>
            <Stack>
              <InputTitle title="SKU" tooltipContent={AssetPoolTooltipContent.sku} />
              <Toggle
                onText="Use Legacy SKU"
                offText="Use Multi Subscriptions"
                checked={skuSelectedOption === 'tenantSku'}
                onChange={handleSkuOptionChange}
                disabled={readOnly}
                styles={{
                  pill: { backgroundColor: skuSelectedOption === 'tenantSku' ? '#0078d4' : '#e1dfdd' },
                  thumb: { backgroundColor: skuSelectedOption === 'tenantSku' ? '#ffffff' : '#0078d4' },
                }}
                id="sku"
              />
              {skuSelectedOption === 'tenantSku' ? (
                <TenantSkuSelector
                  selectedTenantSkuKey={selectedTenantSku}
                  updatedTenantSkuOptions={tenantSkuOptions}
                  handleTenantSkuChange={handleTenantSkuChange}
                  required={parentProfile === 'TenantBase'}
                  readOnly={readOnly}
                />
              ) : (
                <ItemList
                  title={'Subscription'}
                  items={subscriptions}
                  columns={SubscriptionsColumns(subscriptions, setSubscriptions)}
                  newItem={newSubscription}
                  setNewItem={setNewSubscription}
                  handleAddItem={() => handleAddingSubscriptions(newSubscription)}
                  handleTextChange={(event, newValue) =>
                    handleTextChange(
                      event,
                      (value) => setNewSubscription({ ...newSubscription, SkuType: value }),
                      newValue
                    )
                  }
                  handleNumberInputChange={(newValue) =>
                    handleNumberInputChange(
                      setSubscriptionLicenseCountErrorMessage,
                      setIsSubscriptionLicenseCountValid,
                      (value) => setNewSubscription({ ...newSubscription, LicenseCount: value }),
                      newValue
                    )
                  }
                  textFieldLabel="Sku Type"
                  numberFieldLabel="License Count"
                  numberFieldErrorMessage={subscriptionLicenseCountErrorMessage}
                  isNumberFieldValid={isSubscriptionLicenseCountValid}
                  readOnly={readOnly}
                  isDropdown={true}
                  dropdownOptions={skuTypeOptions}
                  required={true}
                />
              )}
            </Stack>
            <Stack>
              <InputTitle
                title={`Hydration Target (${hydrationTargets.length})`}
                tooltipContent={AssetPoolTooltipContent.hydrationTarget}
              />
              <ItemList
                title={'Hydration Target'}
                items={hydrationTargets}
                columns={HydrationTargetColumns(hydrationTargets, setHydrationTargets)}
                newItem={newHydrationTarget}
                setNewItem={setNewHydrationTarget}
                handleAddItem={() => handleAddingHydrationTargets(newHydrationTarget)}
                handleTextChange={(event, newValue) =>
                  handleTextChange(
                    event,
                    (value) => setNewHydrationTarget({ ...newHydrationTarget, ProfileName: value }),
                    newValue
                  )
                }
                handleNumberInputChange={(newValue) =>
                  handleNumberInputChange(
                    setNewHydrationTargetCountErrorMessage,
                    setIsNewHydrationTargetCountValid,
                    (value) => setNewHydrationTarget({ ...newHydrationTarget, Count: value }),
                    newValue
                  )
                }
                textFieldLabel="Profile"
                numberFieldLabel="Count"
                numberFieldErrorMessage={newHydrationTargetCountErrorMessage}
                isNumberFieldValid={isNewHydrationTargetCountValid}
                readOnly={readOnly}
              />
            </Stack>
            <Stack>
              <InputTitle
                title="Exchange Service Instance"
                tooltipContent={AssetPoolTooltipContent.exchangeServiceInstance}
              />
              <TextField
                id="exchange-service-instance"
                value={exchangeServiceInstance}
                onChange={(event, newValue) => handleTextChange(event, setExchangeServiceInstance, newValue)}
                required={false}
                disabled={readOnly}
              />
            </Stack>
            <Stack>
              <InputTitle title="Spo Service Instance" tooltipContent={AssetPoolTooltipContent.spoServiceInstance} />
              <TextField
                id="spo-service-instance"
                value={spoServiceInstance}
                onChange={(event, newValue) => handleTextChange(event, setSpoServiceInstance, newValue)}
                required={false}
                disabled={readOnly}
              />
            </Stack>
            <Stack>
              <InputTitle
                title="Planner Service Instance"
                tooltipContent={AssetPoolTooltipContent.plannerServiceInstance}
              />
              <TextField
                id="planner-service-instance"
                value={plannerServiceInstance}
                onChange={(event, newValue) => handleTextChange(event, setPlannerServiceInstance, newValue)}
                required={false}
                disabled={readOnly}
              />
            </Stack>
            <Stack>
              <InputTitle title="Usage Location" tooltipContent={AssetPoolTooltipContent.usageLocation} />
              <Dropdown
                id="usage-location"
                placeholder="Select an option"
                options={countryOptions}
                selectedKey={usageLocation}
                onChange={handleUsageLocationChange}
                required={false}
                disabled={readOnly}
                styles={dropdownStyles}
              />
            </Stack>
            <Stack>
              <InputTitle title="Daily Quota" tooltipContent={AssetPoolTooltipContent.dailyQuota} />
              <TextField
                id="daily-quota"
                type="number"
                value={dailyQuota?.toString()}
                min={0}
                onChange={(event, newValue) =>
                  handleNumberInputChange(setDailyQuotaErrorMessage, setIsDailyQuotaValid, setDailyQuota, newValue)
                }
                errorMessage={dailyQuotaErrorMessage}
                disabled={readOnly}
              />
            </Stack>
          </>
        )}
      </Stack>
      {managePool && action !== ReadAction && (
        <Stack tokens={{ childrenGap: 10 }}>
          <PrimaryButton
            text="Submit"
            onClick={handleSubmit}
            disabled={submittingManagePoolRequest || !isCostValid || !isTargetPoolSizeValid || !isDailyQuotaValid}
            data-testid="submit-button"
            styles={{ root: { marginTop: '10px' } }}
          />
          <ConfirmationWithInputDialog
            isVisible={isDialogVisible}
            response={userInput}
            title={`Justification Required`}
            subText={`Please provide reasons for this request.`}
            textFieldLabel={`Justification`}
            onDismiss={handleDialogClose}
            onSubmit={handleDialogSubmit}
            onInputChange={handleUserInputChange}
          />
        </Stack>
      )}
      {submittingManagePoolRequest && <Spinner label={`${getActionGerund(action)} pool...`} ariaLive="assertive" />}
      {defaultMessage.length > 0 && messageType !== null && (
        <ExtendableMessageBar
          messageType={messageType}
          shortMessage={defaultMessage}
          additionalMessage={additionalMessage}
          data-testid="error-message-bar"
        />
      )}{' '}
    </>
  );
};

export default CreateOrUpdateAssetPool;
