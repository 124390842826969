import { ConstrainMode, SelectionMode, Text } from '@fluentui/react';
import React from 'react';

import { ITestResource } from '../../constants/interfaces';
import { ExtendedDetailsList } from '../common/ExtendedDetailsList';
import { TestResourcesColumns } from './TestResources.columns';
import LoadingSpinner from '../common/LoadingSpinner';

type ITestResourceTableProps = {
  testResource: ITestResource;
  isFetching: boolean;
};

export const TestResourceTable: React.FC<ITestResourceTableProps> = ({ testResource, isFetching }) => {
  const renderTable = () => {
    if (isFetching) return;

    if (testResource) {
      return (
        <>
          <>
            <Text variant="large" style={{ marginTop: '1rem', fontWeight: 'bold' }}>
              Test Resources
            </Text>
            <div
              style={{
                height: '1px',
                backgroundColor: '#ccc',
              }}
            />
          </>

          <ExtendedDetailsList
            items={testResource.resources}
            columns={TestResourcesColumns()}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            exportFileName="TestResourceTable"
          />
        </>
      );
    }
  };

  return (
    <>
      {isFetching && <LoadingSpinner title={`Loading test resources for ${testResource.name}...`} />}

      {renderTable()}
    </>
  );
};
