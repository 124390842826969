import React, { useEffect, useState } from 'react';

import { IExtendedColumn, IExtendedDetailsListProps } from './ExtendedDetailsList';
import ExtendedDetailsListBase from './ExtendedDetailsListBase';
import * as tableUtils from './tableUtils';

export default function ExtendedDetailsListSorting<T extends object>(props: IExtendedDetailsListProps<T>): JSX.Element {
  /**
   * Given a set of columns as well as a key, find and return the
   * IExtendedColumn to initially sort by. If no key is provided,
   * the first column where `IExtendedColumn.isSortable` is true
   * is returned.
   *
   * @param columns Columns for which to sort
   * @param columnKey (Optional) The key for which column to sort on
   */
  const SetDefaultSortBy = (columns: IExtendedColumn[], columnKey?: string): IExtendedColumn => {
    let key = columnKey;

    if (!columnKey) {
      const firstSortableColumn = columns.find((column) => column.isSortable === true);

      if (firstSortableColumn) key = firstSortableColumn.key;
    }

    return columns.find((column) => column.key === key) ?? columns[0];
  };

  /**
   * On render, set the default column to sort by
   */
  const [columnToSortBy, setColumnToSortBy] = useState<IExtendedColumn>(
    SetDefaultSortBy(props.columns, props.defaultSortBy)
  );

  const onColumnClick = (ev: React.MouseEvent<HTMLElement>, clickedColumn: IExtendedColumn): void => {
    setColumnToSortBy(clickedColumn);
  };

  /**
   * Given the initial set of props, for the columns classified
   * as sortable, pass in the reference to onColumnClick method
   *
   * @param oldProps The fresh props received on render
   */
  const prepareProps = (oldProps: IExtendedDetailsListProps<T>): IExtendedDetailsListProps<T> => {
    const newProps = { ...oldProps };
    newProps.columns.forEach((col: IExtendedColumn) => {
      if (col.isSortable) {
        col.onColumnClick = onColumnClick;
      }
    });

    if (columnToSortBy.fieldName === undefined) {
      // eslint-disable-next-line
      console.error('Unable to sort by current column since fieldName property is undefined.', columnToSortBy);

      return newProps;
    }

    newProps.items = tableUtils.CopyAndSortTableRows(
      newProps.items,
      columnToSortBy.fieldName,
      columnToSortBy.isSortedDescending,
      newProps.propertiesToGroupBy
    );

    return newProps;
  };

  const [currentProps, setCurrentProps] = useState<IExtendedDetailsListProps<T>>(prepareProps(props));

  const [currentColumns, setCurrentColumns] = useState<IExtendedColumn[]>(props.columns);

  /**
   * Handle change of columnToSortBy or props.columns
   *
   * When the columnToSortBy is changed, that means a user has clicked
   * on one of the sortable columns. Given the new columnToSortBy,
   * generate a new set of columns to replace currentColumns.
   */
  useEffect(() => {
    // create a copy of the current columns
    const newColumns: IExtendedColumn[] = props.columns.slice();

    // filter out the column being sorted
    const currColumn: IExtendedColumn = newColumns.filter((currCol) => columnToSortBy.key === currCol.key)[0];

    // specify the "isSorted" fields currectly on the columns
    newColumns.forEach((newCol: IExtendedColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setCurrentColumns(newColumns);
  }, [columnToSortBy, props.columns]);

  /**
   * Handle change of currentColumns or props.items
   *
   * When the new currentColumns are defined, or when props.items
   * changes (which we see whenever a new filter is applied/removed),
   * apply the currentColumns against props.items to update the
   * currentProps
   */
  useEffect(() => {
    // filter out the column being sorted
    const sortingColumn: IExtendedColumn = currentColumns.filter((currentColumn) => currentColumn.isSorted)[0];

    if (sortingColumn.fieldName === undefined) {
      // eslint-disable-next-line
      console.error('Unable to sort by current column since fieldName property is undefined.', columnToSortBy);
      return;
    }

    // apply the sorting to the new columns
    const newItems = tableUtils.CopyAndSortTableRows(
      props.items,
      sortingColumn.fieldName,
      sortingColumn.isSortedDescending,
      props.propertiesToGroupBy
    );

    const newProps = { ...props };
    newProps.columns = currentColumns;
    newProps.items = newItems;

    // update the columns now being sorted
    setCurrentProps(newProps);

    // NOTE: we are purposefully not depending on the entire "props" object
  }, [columnToSortBy, currentColumns, props, props.items]);

  return <ExtendedDetailsListBase {...currentProps} />;
}
