import { DefaultButton, DefaultEffects, IColumn, IDetailsListProps, Panel, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { NeutralColors } from '@fluentui/theme';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';

import ExtendedDetailsListBase from './ExtendedDetailsListBase';
import ExtendedDetailsListSorting from './ExtendedDetailsListSorting';
import { BooleanFilter } from './Filters/BooleanFilter';
import { DateRangeFilter } from './Filters/DateRangeFilter';
import { DropDownFilter } from './Filters/DropDownFilter';
import { TextFilter } from './Filters/TextFilter';
import { convertToSearchParams, countAppliedFilters, FilterRules, FilterType, processRules } from './filterUtils';

export interface IExtendedColumn extends IColumn {
  /** Controls whether the column is sortable. */
  isSortable?: boolean;
  /** Controls whether the column is filterable. */
  filterType?: FilterType;
  /** Controls whether column has a default filter value */
  filterDefault?: boolean | string | string[];
}

export interface IExtendedDetailsListProps<T extends object> extends IDetailsListProps {
  /** column defitions. If none are provided, default columns will be created based on the items' properties. */
  columns: IExtendedColumn[];
  /**
   * The key of the column to have the table sorted by by default
   */
  defaultSortBy?: string;
  /**
   * The items to render in the details list
   */
  items: T[];
  // Export File Name
  exportFileName?: string;
  /**
   * List of the name of the properties to group the items by in the extended details list.
   */
  propertiesToGroupBy?: (keyof T)[];
}

function generateFilterRules<T extends object>(
  column: IExtendedColumn,
  filterRule: FilterRules<T>,
  searchParams: URLSearchParams
) {
  if (!column.fieldName) {
    console.error('no fieldname defined for column, will not be filterable');
    return;
  }

  const key = column.fieldName as keyof T;
  const filterDefault = column.filterDefault;
  const providedParam = searchParams.get(column.fieldName);

  switch (column.filterType) {
    case FilterType.DropDown: {
      let initialDropdownFilter: string[] = [];

      if (providedParam) {
        initialDropdownFilter = decodeURI(providedParam).split(',');
      } else if (filterDefault && Array.isArray(filterDefault)) {
        initialDropdownFilter = filterDefault;
      }

      filterRule[key] = {
        type: FilterType.DropDown,
        dropdownFilter: initialDropdownFilter,
      };

      break;
    }
    case FilterType.Boolean: {
      let initialBooleanFilter = undefined;

      if (providedParam) {
        initialBooleanFilter = providedParam.toLowerCase() === 'true';
      } else if (filterDefault && typeof filterDefault === 'boolean') {
        initialBooleanFilter = filterDefault;
      }

      filterRule[key] = {
        type: FilterType.Boolean,
        booleanFilter: initialBooleanFilter,
      };

      break;
    }
    case FilterType.Text: {
      let initialTextFilter = '';

      if (providedParam) {
        initialTextFilter = decodeURI(providedParam);
      } else if (filterDefault && typeof filterDefault === 'string') {
        initialTextFilter = filterDefault;
      }

      filterRule[key] = {
        type: FilterType.Text,
        textFilter: initialTextFilter,
      };

      break;
    }
    case FilterType.DateRange: {
      let initialStartDate: Date | undefined;
      let initialEndDate: Date | undefined;

      if (providedParam) {
        const providedDateRangeParam = decodeURI(providedParam).split(',');
        const [potentialStartDate, potentialEndDate] = providedDateRangeParam;

        if (potentialStartDate && !isNaN(Date.parse(potentialStartDate))) {
          initialStartDate = new Date(potentialStartDate);
        }

        if (potentialEndDate && !isNaN(Date.parse(potentialEndDate))) {
          initialEndDate = new Date(potentialEndDate);
        }
      }

      filterRule[key] = {
        type: FilterType.DateRange,
        startDate: initialStartDate,
        endDate: initialEndDate,
      };
    }
  }
}

export function ExtendedDetailsList<T extends object>(props: IExtendedDetailsListProps<T>): JSX.Element {
  const history = useHistory();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const [appliedFiltersCount, setAppliedFiltersCount] = useState<number>(0);

  const columnsWithFilters = useMemo<IExtendedColumn[]>(
    () => props.columns.filter((column) => column.filterType !== undefined),
    [props.columns]
  );

  const generateDefaultFilters = (): FilterRules<T> => {
    const searchParams = new URLSearchParams(history.location.search);

    const filterRule: FilterRules<T> = {};

    columnsWithFilters.forEach((filteredColumn) => {
      generateFilterRules(filteredColumn, filterRule, searchParams);
    });

    return filterRule;
  };

  const [filterRules, setFilterRules] = useState<FilterRules<T>>(generateDefaultFilters());

  const filteredItems = useMemo(() => {
    const count = countAppliedFilters(filterRules);

    setAppliedFiltersCount(count);

    return processRules(props.items, filterRules);
  }, [filterRules, props.items]);

  useEffect(() => {
    const currentlyConfiguredColumns = Object.keys(filterRules);
    const newFilterableColumns = columnsWithFilters.filter(
      (c) => !currentlyConfiguredColumns.includes(c.fieldName || '')
    );

    const removedColumnsKeys = currentlyConfiguredColumns
      .filter((configuredColumnKey) => {
        return !columnsWithFilters.some((c) => c.fieldName === configuredColumnKey);
      })
      .map((k) => k as keyof T);

    if (newFilterableColumns.length > 0 || removedColumnsKeys.length > 0) {
      // Generate new filterRules object without the removed columns
      const newFilterRules: FilterRules<T> = { ...filterRules };
      removedColumnsKeys.forEach((keyToDelete) => delete newFilterRules[keyToDelete]);

      // Go through each of the newly filterable columns and configure their filter rules
      const searchParams = new URLSearchParams(history.location.search);
      newFilterableColumns.forEach((c) => generateFilterRules(c, newFilterRules, searchParams));

      setFilterRules(newFilterRules);
    }
  }, [columnsWithFilters, filterRules, history.location.search, props.columns]);

  /**
   * Whenever the FilterRules change, make sure to update
   * the URL Search Parameters with the updated changes to
   * the filter rules.
   */
  useEffect(() => {
    const combineSearchParams = (existing: URLSearchParams, updated: URLSearchParams) => {
      updated.forEach((value, key) => {
        if (existing.has(key)) {
          if (existing.get(key) !== value) {
            existing.set(key, value);
          }
        } else {
          existing.set(key, value);
        }
      });

      for (const key in filterRules) {
        if (existing.has(key) && !updated.has(key)) {
          existing.delete(key);
        }
      }

      return existing;
    };

    const currentSearchParams = new URLSearchParams(history.location.search);
    const convertedSearchParams = convertToSearchParams(filterRules);
    const updatedSearchParams = combineSearchParams(currentSearchParams, convertedSearchParams);

    history.replace({ search: updatedSearchParams.toString() });
  }, [filterRules, history]);

  const generateFilters = useMemo<ReactNode>((): ReactNode => {
    if (columnsWithFilters.length === 0) {
      return (
        <Stack tokens={{ childrenGap: 10 }}>
          <Text variant={'mediumPlus'}>No filters currently defined</Text>
          <Text variant={'smallPlus'} style={{ color: NeutralColors.gray110 }}>
            (To enable filtering please define filter type properties on your table columns)
          </Text>
        </Stack>
      );
    }

    // For each of the columns with filters, render the necessary filter
    return columnsWithFilters.map((column: IExtendedColumn, index: number) => {
      switch (column.filterType) {
        case FilterType.Boolean:
          return (
            <BooleanFilter<T>
              key={index.toString()}
              column={column}
              filterRules={filterRules}
              setFilterRules={setFilterRules}
            />
          );

        case FilterType.Text:
          return (
            <TextFilter<T>
              key={index.toString()}
              column={column}
              filterRules={filterRules}
              setFilterRules={setFilterRules}
            />
          );

        case FilterType.DropDown:
          return (
            <DropDownFilter<T>
              key={index.toString()}
              items={props.items}
              column={column}
              filterRules={filterRules}
              setFilterRules={setFilterRules}
            />
          );

        case FilterType.DateRange:
          return (
            <DateRangeFilter<T>
              key={index.toString()}
              column={column}
              filterRules={filterRules}
              setFilterRules={setFilterRules}
            />
          );

        default:
          return <React.Fragment />;
      }
    });
  }, [columnsWithFilters, filterRules, props.items]);

  const generateFiltersAppliedMessage = (): ReactNode => {
    if (columnsWithFilters.length === 0) {
      return <Text>Filtering not enabled for this table</Text>;
    }

    if (appliedFiltersCount === 0) {
      return <Text>No filters currently applied</Text>;
    } else if (appliedFiltersCount === 1) {
      return <Text>1 filter applied</Text>;
    } else {
      return <Text>{appliedFiltersCount} filters applied</Text>;
    }
  };

  // Determine whether the defined columns are sortable. That is, at least
  // one column in the props are decorated with "isSortable: true".
  const columnsAreSortable = props.columns.some((column) => column.isSortable);

  return (
    <>
      <Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 10 }}
          verticalAlign={'end'}
          style={{ marginBottom: '15px' }}
          horizontalAlign={'space-between'}
        >
          <Text>Displaying {filteredItems.length} Results.</Text>

          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
            {generateFiltersAppliedMessage()}

            <DefaultButton
              text="Filters"
              onClick={openPanel}
              primary
              style={{
                width: '100px',
                boxShadow: DefaultEffects.elevation4,
              }}
            />
            <CSVLink data={filteredItems} filename={props.exportFileName + '.csv'}>
              <DefaultButton
                text="Export"
                style={{
                  width: '100px',
                  boxShadow: DefaultEffects.elevation4,
                }}
              />
            </CSVLink>
          </Stack>
        </Stack>

        <Panel
          headerText="Filters"
          isOpen={isOpen}
          onDismiss={dismissPanel}
          closeButtonAriaLabel="Close filters panel"
          isLightDismiss
        >
          <Stack tokens={{ childrenGap: 15 }}>{generateFilters}</Stack>
        </Panel>

        {columnsAreSortable ? (
          <ExtendedDetailsListSorting {...props} items={filteredItems} />
        ) : (
          <ExtendedDetailsListBase {...props} items={filteredItems} />
        )}
      </Stack>
    </>
  );
}
