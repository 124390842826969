import { useState } from 'react';
import { MessageBarType } from '@fluentui/react';

export const useMessageHandler = () => {
  const [defaultMessage, setDefaultMessage] = useState<string>('');
  const [additionalMessage, setAdditionalMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<MessageBarType | null>(null);

  const resetMessage = () => {
    setDefaultMessage('');
    setAdditionalMessage('');
    setMessageType(null);
  };

  const showMessage = (defaultText: string, type: MessageBarType, additionalText?: string) => {
    setDefaultMessage(defaultText);
    setAdditionalMessage(additionalText ?? '');
    setMessageType(type);
  };

  return {
    defaultMessage,
    additionalMessage,
    messageType,
    resetMessage,
    showMessage,
  };
};
