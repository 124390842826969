import React, { useState } from 'react';
import {
  DetailsList,
  IColumn,
  SelectionMode,
  Stack,
  TextField,
  PrimaryButton,
  IDropdownOption,
  Dropdown,
  ActionButton,
  IIconProps,
  MessageBar,
  MessageBarType,
  IconButton,
  Label,
} from '@fluentui/react';
import { useMessageHandler } from './useMessageHandler';
import { HydrationTarget, ISubscription } from '../../constants/interfaces';
import { toKebabCase } from '../../functions/helpers';

type ItemListProps<T> = {
  title: string;
  items: T[];
  columns: IColumn[];
  newItem: T;
  setNewItem: (item: T) => void;
  handleAddItem: () => void;
  handleTextChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  handleNumberInputChange: (newValue?: string) => void;
  textFieldLabel: string;
  numberFieldLabel: string;
  numberFieldErrorMessage: string;
  isNumberFieldValid: boolean;
  readOnly: boolean;
  isDropdown?: boolean;
  dropdownOptions?: IDropdownOption[];
  required?: boolean;
};

const isHydrationTarget = (item: any): item is HydrationTarget => {
  return 'ProfileName' in item && 'Count' in item;
};

const isSubscription = (item: any): item is ISubscription => {
  return 'SkuType' in item && 'LicenseCount' in item;
};

const ItemList = <T extends { ProfileName?: string; Count?: number; SkuType?: string; LicenseCount?: number }>({
  title,
  items,
  columns,
  newItem,
  setNewItem,
  handleAddItem,
  handleTextChange,
  handleNumberInputChange,
  textFieldLabel,
  numberFieldLabel,
  numberFieldErrorMessage,
  isNumberFieldValid,
  readOnly,
  isDropdown = false,
  dropdownOptions = [],
  required = false,
}: ItemListProps<T>) => {
  const addIcon: IIconProps = { iconName: 'Add' };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const [showInputs, setShowInputs] = useState(false);
  const { defaultMessage, additionalMessage, messageType, showMessage, resetMessage } = useMessageHandler();

  const handlePrimaryButtonClick = () => {
    setShowInputs(true);
  };

  const handleAddItemClick = () => {
    const itemExists = items.some((item) =>
      isHydrationTarget(item)
        ? item.ProfileName === newItem.ProfileName
        : isSubscription(item)
        ? item.SkuType === newItem.SkuType
        : false
    );
    if (itemExists) {
      showMessage(`${textFieldLabel} already added!`, MessageBarType.error);
    } else {
      handleAddItem();
      setShowInputs(false);
      showMessage(`${textFieldLabel} added successfully!`, MessageBarType.success);
    }
    setTimeout(resetMessage, 3000); // Hide message after 3 seconds
  };

  const handleCancelClick = () => {
    setShowInputs(false);
  };

  return (
    <>
      {messageType && (
        <MessageBar messageBarType={messageType} isMultiline={false}>
          {defaultMessage} {additionalMessage}
        </MessageBar>
      )}
      {items.length > 0 && <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} />}
      <ActionButton
        onRenderText={() => (
          <>
            {`Add ${title} `}
            {required && <span style={{ color: '#a4262c' }}>&nbsp;*</span>}
          </>
        )}
        iconProps={addIcon}
        onClick={handlePrimaryButtonClick}
        styles={{ root: { width: '200px' } }}
        disabled={readOnly}
        data-testid={`${title}`}
      />
      {showInputs && (
        <Stack horizontal tokens={{ childrenGap: 2 }} styles={{ root: { display: 'flex' } }}>
          {isDropdown ? (
            <Dropdown
              id={toKebabCase(textFieldLabel)}
              placeholder="Select an option"
              label={textFieldLabel}
              options={dropdownOptions}
              selectedKey={isHydrationTarget(newItem) ? newItem.ProfileName : newItem.SkuType}
              onChange={(event, option) =>
                handleTextChange(event as React.FormEvent<HTMLInputElement>, (option?.key as string) ?? '')
              }
              styles={{ root: { width: 175 } }}
              data-testid={toKebabCase(textFieldLabel)}
            />
          ) : (
            <TextField
              id={toKebabCase(textFieldLabel)}
              label={textFieldLabel}
              value={isHydrationTarget(newItem) ? newItem.ProfileName : newItem.SkuType}
              onChange={(event, newValue) => handleTextChange(event, newValue)}
              required={false}
              data-testid={toKebabCase(textFieldLabel)}
            />
          )}
          <TextField
            id={toKebabCase(numberFieldLabel)}
            label={numberFieldLabel}
            type="number"
            min={1}
            value={isHydrationTarget(newItem) ? newItem.Count?.toString() : newItem.LicenseCount?.toString()}
            onChange={(event, newValue) => handleNumberInputChange(newValue)}
            required={false}
            errorMessage={numberFieldErrorMessage}
            data-testid={toKebabCase(numberFieldLabel)}
          />
          <PrimaryButton
            text={`Add`}
            onClick={handleAddItemClick}
            disabled={
              !isNumberFieldValid ||
              readOnly ||
              (isHydrationTarget(newItem) ? newItem.ProfileName.trim() === '' : (newItem.SkuType ?? '').trim() === '')
            }
            styles={{ root: { marginTop: '28px' } }}
            data-testid={`add-item`}
          />
          <IconButton
            iconProps={cancelIcon}
            onClick={handleCancelClick}
            styles={{ root: { marginTop: '28px', color: 'red' }, rootHovered: { color: 'red' } }}
          />
        </Stack>
      )}
    </>
  );
};

export default ItemList;
