import { DatePicker, DayOfWeek, IDatePickerStrings, mergeStyleSets } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import * as React from 'react';

const DayPickerStrings: IDatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker',
  monthPickerHeaderAriaLabel: '{0}, select to change the year',
  yearPickerHeaderAriaLabel: '{0}, select to change the month',

  isRequiredErrorMessage: 'Start date is required.',

  invalidInputErrorMessage: 'Invalid date format.',
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
});

const firstDayOfWeek = DayOfWeek.Sunday;
const desc = 'This field is required. One of the support input formats is year dash month dash day.';

export interface IFilterDatePickerProps {
  onSelectDate: (date: Date | null | undefined) => void;
  label: string;
  value: Date | undefined;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export const FilterDatePicker: React.FC<IFilterDatePickerProps> = (props) => {
  const { onSelectDate, label, value, minDate, maxDate, disabled } = props;

  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
        <DatePicker
          className={controlClass.control}
          label={label}
          isRequired={false}
          allowTextInput={true}
          ariaLabel={desc}
          firstDayOfWeek={firstDayOfWeek}
          strings={DayPickerStrings}
          value={value}
          onSelectDate={onSelectDate}
          style={{ marginBottom: 0 }}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
      </Stack>
    </>
  );
};
