import { AnyAction, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { tripsApi } from '../../api/tripsApi';
import appReducer from './appSlice';
import poolsReducer from './poolsSlice';

const rootReducer = combineReducers({
  appStatus: appReducer,
  pools: poolsReducer,
  [tripsApi.reducerPath]: tripsApi.reducer,
});

export type IRootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType> = ThunkAction<Promise<ReturnType>, IRootState, unknown, AnyAction>;

export default rootReducer;
