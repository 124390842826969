import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

type ILoadingSpinnerProps = {
  title?: string;
};

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ title }) => {
  return (
    <div className="center">
      <Spinner size={SpinnerSize.large} label={title ?? 'Loading...'} />
    </div>
  );
};

export default LoadingSpinner;
