import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { authProvider } from '../auth/authConfiguration';
import { getEnvironmentConfig } from '../config/environmentConfig';

const { baseUrl } = getEnvironmentConfig();

/**
 * Helper method that returns the response of a fetch.
 *
 * @param method - The HTTP method request type
 * @param uri - The API URL
 * @returns The response of the api call
 */
export const fetchCall = async <T,>(method: string, uri: string, expectResponse = true, body?: any): Promise<T> => {
  const token = await authProvider.getApiToken();

  if (token === null) {
    throw new Error('Error getting access token');
  }

  if (uri.startsWith('/'))
    uri = baseUrl + uri;

  let response: Response;
  try {
    response = await fetch(uri, {
      method: method,
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: window.location.origin,
      },
      body: body ? JSON.stringify(body) : undefined,
    });
  } catch (e) {
    // @ts-expect-error Disregarding type error here since we're working to convert all endpoints to RTK-Query
    throw new Error(`${method} ${e.url} (${e.message}).`);
  }

  if (response.status === 204) {
    console.log('Response status: 200');
    return {} as T;
  }

  if (response.status !== 200 && response.status !== 204) {
    let errorText = await response.text();

    try {
      errorText = JSON.parse(errorText);
    } catch {
      // eslint-disable-next-line no-console
      console.log('error text could not be converted to JSON');
    }
    console.error(`Error response: ${errorText}`);
    throw new Error(`${method} ${response.url} \n\n Message: ${errorText}`);
  }

  if (expectResponse) {
    return (await response.json()) as T;
  } else {
    return {} as T;
  }
};

/**
 * Appends startDate and endDate as ISO strings to the provided URI
 * if either startDate or endDate are defined
 *
 * @param uri URI to append startDate and endDate to
 * @param startDate the startDate
 * @param endDate the endDate
 * @returns URI appended with startDate and/or endDate
 */
export const appendDatesToUri = (uri: string, startDate?: Date, endDate?: Date): string => {
  if (startDate || endDate) {
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.set('startDate', startDate.toISOString());
    if (endDate) queryParams.set('endDate', endDate.toISOString());

    return uri + '?' + queryParams.toString();
  } else {
    return uri;
  }
};

export type ExtendedFetchBaseQueryError = FetchBaseQueryError & {
  url?: string;
  statusText?: string;
};

/**
 * Type definition for extended BaseQuery with url
 * in the error response
 */
type ExtendedBaseQuery = BaseQueryFn<string | FetchArgs, unknown, ExtendedFetchBaseQueryError>;

/**
 * Create a new baseQuery instance used for the createApi method
 * from RTK-Query
 *
 * @param baseUrl the base URL
 * @returns An instance of fetchBaseQuery extended to include URL information in the error response
 */
export const createBaseQuery = (args: FetchBaseQueryArgs): ExtendedBaseQuery => {
  // Initialize baseQuery with fetchBaseQuery
  const baseQuery = fetchBaseQuery(args);

  // Construct base query function that will
  // include the request/response URL in the error
  const baseQueryWithUrl: ExtendedBaseQuery = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error !== undefined) {
      return {
        ...result,
        error: {
          ...result.error,
          url: result.meta?.request.url,
          statusText: result.meta?.response?.statusText,
        },
      };
    }
    return result;
  };

  return baseQueryWithUrl;
};
