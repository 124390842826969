import './styles/App.css';
import { Authentication } from './auth/Authentication';
import { authProvider } from './auth/authConfiguration';
import { ThemeProvider, createTheme } from '@fluentui/react';
import { ReactFlowProvider } from 'reactflow';
import Router from './routes/Router';
import SidebarMenu from './components/common/SidebarMenu';
import SmartNav from './components/common/SmartNav';
import UserAnnouncement from './components/AdminActions/UserAnnouncements/UserAnnouncement';

const themeOverrides = createTheme({
  semanticColors: {
    /**
     * Color Override Justification:
     *
     * This is the suggested color code by Accessibility Insights to make sure
     * that Links are accessible with our ExtendeDetailsList in the alternating
     * row background-color scheme.
     */
    link: '#0072c9',
  },
});

function App() {
  return (
    <div className="App">
      <Authentication provider={authProvider} forceLogin>
        <ThemeProvider theme={themeOverrides} applyTo="body">
          <ReactFlowProvider>
            <div className="header">
              <SmartNav />
            </div>
            <div>
              <UserAnnouncement />
            </div>
            <div className="body">
              <div className="main">
                <Router />
              </div>
              <div className="sidebar">
                <SidebarMenu />
              </div>
            </div>
          </ReactFlowProvider>
        </ThemeProvider>
      </Authentication>
    </div>
  );
}

export default App;
