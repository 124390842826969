import { SetStateAction, useEffect, useState } from 'react';
import { UpdatePool } from '../../../api/tripsApi';
import CreateOrUpdateAssetPool from './CreateOrUpdateAssetPool';
import { IPool } from '../../../constants/interfaces';
import { Dropdown, IDropdownOption, Spinner, Stack, Text } from '@fluentui/react';
import { arrayToDropdownOptions } from '../../common/tableUtils';
import { UpdateAction } from '../../../constants/constants';
import { dropdownStyles } from '../../../constants/styles';

export interface IManageUpdateAssetPoolProps {
  poolNames: string[];
  getAssetPoolByName: any;
}

const ManageUpdateAssetPool: React.FC<IManageUpdateAssetPoolProps> = ({ poolNames, getAssetPoolByName }) => {
  const [poolToUpdate, setPoolToUpdate] = useState<IPool>();
  const [selectedAssetPoolKey, setSelectedAssetPoolKey] = useState<string>('');
  const [retrievingPoolRequest, setRetrievingPoolRequest] = useState<boolean>(false);

  const handleAssetPoolChange = async (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    setSelectedAssetPoolKey(option ? (option.key as string) : '');
  };

  useEffect(() => {
    if (selectedAssetPoolKey) {
      getAssetPoolByName(selectedAssetPoolKey).then((p: SetStateAction<IPool | undefined>) => setPoolToUpdate(p));
      setRetrievingPoolRequest(true);
    }
  }, [selectedAssetPoolKey]);

  useEffect(() => {
    setRetrievingPoolRequest(false);
  }, [poolToUpdate]);

  const sortedPoolNames = [...poolNames].sort((a, b) => a.localeCompare(b));

  return (
    <Stack>
      {retrievingPoolRequest && (
        <Spinner label="Retrieving pool for update..." ariaLive="assertive" /> // ANNA: hmm how to get the action in here
      )}
      <Dropdown
        label="Asset pool to update"
        options={arrayToDropdownOptions(sortedPoolNames)}
        selectedKey={selectedAssetPoolKey}
        onChange={handleAssetPoolChange}
        styles={dropdownStyles}
      />
      {poolToUpdate != null && (
        <div key={poolToUpdate.PoolName}>
          <CreateOrUpdateAssetPool managePool={UpdatePool} action={UpdateAction} assetPool={poolToUpdate} />
        </div>
      )}
    </Stack>
  );
};

export default ManageUpdateAssetPool;
