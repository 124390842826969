import { configureStore, PreloadedState } from '@reduxjs/toolkit';

import { tripsApi } from '../api/tripsApi';
import rootReducer, { IRootState } from './slices';

/**
 * Function to initialize a new store and apply middleware
 *
 * @param initialState The initial state for the global store.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: PreloadedState<IRootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: ['payload', 'meta'],
          ignoredPaths: ['topologyConsoleApi'],
        },
      }).concat(tripsApi.middleware),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
