import React from 'react';
import { useParams } from 'react-router-dom';

import { ErrorRenderer } from '../../common/ErrorRenderer';
import Layout from '../../common/Layout';
import LoadingSpinner from '../../common/LoadingSpinner';
import { LeasedTenantTable } from './LeasedTenantTable';
import LeasedTenantHeader from './LeasedTenantHeader';
import { useGetMyTenantLeaseQuery } from '../../../api/tripsApi';

interface ILeasedTenantPageRouteParams {
  tenantId: string;
}

const LeasedTenantPage: React.FC = () => {
  const { tenantId } = useParams<ILeasedTenantPageRouteParams>();

  const { data: tenant, isFetching, error } = useGetMyTenantLeaseQuery(tenantId);

  return (
    <Layout>
      {error && <ErrorRenderer error={error} />}
      {isFetching && <LoadingSpinner title={`Loading data for tenant : ${tenantId}.`} />}

      {tenant && (
        <>
          <LeasedTenantHeader leasedTenant={tenant} />
          <LeasedTenantTable leasedTenant={tenant} isFetching={isFetching} />
        </>
      )}
    </Layout>
  );
};

export default LeasedTenantPage;
