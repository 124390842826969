import React, { useState } from 'react';
import { MessageBar, MessageBarType, Link } from '@fluentui/react';

interface ExtendableMessageBarProps {
  shortMessage: string;
  additionalMessage?: string;
  messageType: MessageBarType;
  shortMessageLength?: number;
}

const ExtendableMessageBar: React.FC<ExtendableMessageBarProps> = ({
  shortMessage,
  additionalMessage,
  messageType,
  shortMessageLength = 100,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const displayedMessage = isExpanded
    ? shortMessage + (additionalMessage ? ' ' + additionalMessage : '')
    : shortMessage.slice(0, shortMessageLength) + (shortMessage.length > shortMessageLength ? '...' : '');

  const parseMessage = (message: string) => {
    const linkRegex = /<a href="([^"]+)">([^<]+)<\/a>/;
    const parts = message.split(linkRegex);
    return parts;
  };

  const messageParts = parseMessage(displayedMessage);

  return isVisible ? (
    <MessageBar
      title={shortMessage}
      messageBarType={messageType}
      isMultiline={isExpanded}
      styles={{ root: { marginTop: '10px' } }}
      onDismiss={handleClose}
      dismissButtonAriaLabel="Close"
    >
      <div
        style={{
          display: 'inline',
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100% - 60px)',
        }}
      >
        {messageParts.map((part, index) => {
          if (index % 3 === 0) {
            return part;
          } else if (index % 3 === 1) {
            return (
              <Link key={index} href={part}>
                {messageParts[index + 1]}
              </Link>
            );
          } else {
            return null;
          }
        })}
      </div>
      {additionalMessage && (
        <>
          <Link onClick={toggleExpanded} style={{ marginLeft: '4px' }}>
            {isExpanded ? 'See less' : 'See more'}
          </Link>
        </>
      )}
    </MessageBar>
  ) : null;
};

export default ExtendableMessageBar;
